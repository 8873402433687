import "App.scss";
import React from "react";
import RouterIndex from "route";
import { PullToRefresh } from "antd-mobile";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <PullToRefresh
          onRefresh={() => {
            window.location.reload();
          }}
        >
          <RouterIndex></RouterIndex>
        </PullToRefresh>
      </div>
    );
  }
}

export default App;
