import { Dialog, Toast } from "antd-mobile";
import axios from "axios";
import NavBar from "components/navBar";
import React, { useCallback, useEffect, useState } from "react";
import { getStorage } from "utils/storage";
import "./index.scss";
import { useNavigate } from "react-router-dom";

export function ComboSee() {
  const [scorllTop, setScorllTop] = useState(0),
    scrollElemet = useCallback(() => {
      setScorllTop(document.documentElement.scrollTop);
    }, []),
    navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("scroll", scrollElemet);
    return () => {
      window.removeEventListener("scroll", scrollElemet);
    };
  }, [scrollElemet]);
  return (
    <div className="combo_see">
      <img src={require("images/image_06.png")} alt="" className="combe_bg" />
      <NavBar
        bgColor={scorllTop > 30 ? "#0ea5fb " : "#fffff00"}
        backShow={true}
        textColor="#ffffff"
        navText="套餐信息"
        backColor="#ffffff"
      ></NavBar>
      <div className="combo_list">
        {comboList.map((item, index) => {
          return (
            <div className="c_list_item" key={index}>
              <div className="c_item_title">{item.title}</div>
              <div className="c_item_strip">
                <div className="strip_item">
                  <div>{item.strip}</div>
                  <div>挂短条数</div>
                </div>
                <div className="strip_item">
                  <div>{item.groupStrip}</div>
                  <div>群发条数</div>
                </div>
              </div>
              <div
                className="combo_order"
                onClick={() => {
                  Dialog.confirm({
                    content: `是否提交订购${item.title}套餐？`,
                    onConfirm: async () => {
                      await axios({
                        url: `https://zhineng.lio8.com/1/api/toemail?token=${getStorage()}`,
                        method: "POST",
                        params: {
                          title: "套餐",
                          content: `${item.title}——挂短条数：${item.strip}——群发条数：${item.groupStrip}`,
                        },
                      })
                        .then((res) => {
                          if (res.data.code == 0) {
                            Toast.show({
                              content: "提交成功",
                              icon: "success",
                              maskClickable: false,
                              afterClose: () => navigate(-1, { replace: true }),
                            });
                          } else {
                            Toast.show({
                              content: "提交失败",
                              icon: "fail",
                              maskClickable: false,
                            });
                          }
                        })
                        .catch((err) => {
                          Toast.show({ content: "数据请求失败，请稍后重试" });
                        });
                    },
                  });
                }}
              >
                立即订购
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const comboList = [
  {
    title: "商信通10元版",
    strip: 200,
    groupStrip: 100,
  },
  {
    title: "商信通18元版",
    strip: 400,
    groupStrip: 200,
  },
  {
    title: "商信通28元版",
    strip: 600,
    groupStrip: 300,
  },
  {
    title: "商信通38元版",
    strip: 880,
    groupStrip: 400,
  },
  {
    title: "商信通58元版",
    strip: 1400,
    groupStrip: 600,
  },
];
