import TabBar from "components/home/tabBar";
import HomeRouter from "components/home/homeRouter";

import { homeRouter } from "route/modules/homeRouter";
import { useEffect } from "react";
// import { getStorage, setStorage } from "utils/storage";

function Index() {
  useEffect(() => {
    // if (window.location.search) {
    //   setStorage(window.location.search.split("=")[1]);
    // }
    return () => {};
  }, []);
  return (
    <div style={tabBarCss.home}>
      <div style={tabBarCss.home_content}>
        <HomeRouter navBarList={homeRouter}></HomeRouter>
      </div>
      <div style={tabBarCss.home_tab_bar}>
        <TabBar navBarList={homeRouter}></TabBar>
      </div>
    </div>
  );
}

const tabBarCss = {
  home: {
    position: "relative",
    height: "100vh",
  },
  home_content: {
    paddingBottom: "1.33rem",
  },
  home_tab_bar: {
    left: "0",
    bottom: " 0",
    width: "100vw",
    height: "1.33rem",
    position: "fixed",
    backgroundColor: " #fff",
    zIndex: " 99",
  },
};

export default Index;
