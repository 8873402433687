import React from "react";
import "./index.scss";
import { List, Collapse, Toast } from "antd-mobile";
import PubSub from "pubsub-js";

class ProfileList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <List
        className="profile_list_"
        style={{
          "--border-top": "none",
          "--border-bottom": "0.27rem solid #F7F7F7",
          "--padding-left": "0",
          padding: "0 0.4rem",
        }}
      >
        {this.props.list.map((item, index) => {
          return item.collapse ? (
            <Collapse className="collapse" key={index}>
              <Collapse.Panel
                title={
                  <div className="flex_items">
                    <img
                      style={{ marginRight: 12 }}
                      src={item.icon}
                      alt={item.title}
                    ></img>
                    {item.title}
                  </div>
                }
                key="1"
              >
                <div className="collapse_content">
                  {item.children.map((item, index) => {
                    return (
                      <div className="flex_justify" key={index}>
                        <div
                          className="collapse_item flex_justify"
                          onClick={() => {
                            item.routerStatus
                              ? item.path
                                ? PubSub.publish("navigate", {
                                    path: item.path,
                                    type: 1,
                                  })
                                : Toast.show({ content: item.title })
                              : window.location.assign(item.path);
                          }}
                        >
                          <div className="flex_justify">
                            <img alt={item.title} src={item.icon}></img>
                          </div>
                          <div>{item.title}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Collapse.Panel>
            </Collapse>
          ) : (
            <List.Item
              prefix={<img src={item.icon} alt={item.title}></img>}
              key={index}
              onClick={() => {
                item.routerStatus
                  ? item.path
                    ? PubSub.publish("navigate", { path: item.path, type: 1 })
                    : Toast.show({ content: item.title })
                  : window.location.assign(item.path);
              }}
            >
              <span>{item.title}</span>
            </List.Item>
          );
        })}
      </List>
    );
  }
}

export default ProfileList;
