import { useLocation } from "react-router-dom";
import NavBar from "components/navBar";
import "./index.scss";
import { useEffect, useState } from "react";
import { Checkbox, Empty, Toast } from "antd-mobile";
import { getTimer_ } from "utils";

function Remark() {
  const { phone, descriptions } = useLocation().state;
  const [remarkList, setRemarkList] = useState(remarkList_()),
    [deleteStatus, setDeleteStatus] = useState(false),
    [selectList, setSelectList] = useState([]),
    [des, setDes] = useState("");
  const deleteClick = () => {
    if (!deleteStatus) return setDeleteStatus(true);
    if (selectList.length === 0) return setDeleteStatus(false);
    let remarkList_ = remarkList.filter((item, index) => {
      return selectList.indexOf(item.id) === -1;
    });
    Toast.show({ content: "删除成功" });
    setRemarkList([...remarkList_]);
    setSelectList([]);
  };
  useEffect(() => {
    for (let i in descriptions) {
      setDes(descriptions[i]);
    }
    return () => {};
  }, []);
  return (
    <div className="remark">
      <NavBar navText={phone} backShow={true}></NavBar>
      {des ? (
        <>
          {" "}
          <div className="remark_list">
            <Checkbox.Group
              onChange={(e) => {
                setSelectList(e);
              }}
            >
              {/* {remarkList.map((item, index) => {
                return ( */}
              <div className="r_list_item">
                <div className="l_item_time">{getTimer_(des.createdAt)}</div>
                <div className="l_item_title" style={{ marginTop: ".93rem" }}>
                  备注事件
                </div>
                <div className="l_item_value">{des.desc}</div>
                {/* <div className="delete_status">
                  {deleteStatus ? (
                    <Checkbox
                      value={item.id}
                      style={{ "--adm-color-primary": "#0EA5FB" }}
                    ></Checkbox>
                  ) : null}
                </div> */}
              </div>
              {/* })} */}
            </Checkbox.Group>
          </div>
          {/* <div className="remark_delete">
            <div onClick={() => deleteClick()}>
              {deleteStatus ? "确认删除" : "删除备注"}
            </div>
          </div> */}
        </>
      ) : (
        <Empty
          style={{ padding: "240px 0" }}
          imageStyle={{ width: 170 }}
          description="暂无数据"
        ></Empty>
      )}
    </div>
  );
}

const remarkList_ = () => {
  let remarkList = [];
  for (let i = 0; i < 5; i++) {
    remarkList.push({
      id: i,
      time: "2021-12-31  16:24:48",
      value:
        "作者皆殊列，名声岂浪垂。骚人嗟不见，汉道盛于斯。前辈飞腾入，馀波绮丽为。后贤兼旧列，历代各清规" +
        i,
    });
  }
  return remarkList;
};

export default Remark;
