// 通话记录
import instance from "utils/axios";

export function getCallHistory(status) {
  return instance({
    url: "callLogs",
    params: status,
  });
}

// 添加备注内容
export function remarkMessage(options) {
  return instance({
    url: `/callLogs/${options.id}`,
    method: "PATCH",
    data: options.data,
  });
}
