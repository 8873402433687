import React from "react";
import "./index.scss";
import { Link } from "react-router-dom";

class TabBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabBarStatus: 0,
    };
  }

  componentDidMount() {
    for (let item of this.props.navBarList) {
      let index = this.props.navBarList.indexOf(item);
      // console.log(item.path, index, window.location.href)
      if (window.location.href.includes(`/${item.path}`)) {
        this.setState({
          tabBarStatus: index,
        });
      }
    }
  }

  tabBarClick = (index) => {
    this.setState({ tabBarStatus: index });
  };

  render() {
    return (
      <div className="tab_bar">
        {this.props.navBarList.map((item, index) => {
          return (
            <div key={index} className="flex_justify">
              <Link
                replace
                to={item.path}
                className="flex_justify"
                onClick={() => {
                  this.tabBarClick(index);
                }}
              >
                <div className="bar_img flex_justify">
                  <img
                    src={
                      this.state.tabBarStatus === index
                        ? item.meta.selestIcon
                        : item.meta.icon
                    }
                    alt={item.meta.title}
                  ></img>
                </div>
                <div className="bar_title flex_justify">
                  <span
                    style={
                      this.state.tabBarStatus === index
                        ? { color: "#0EA5FB" }
                        : { color: "#999999" }
                    }
                  >
                    {item.meta.title}
                  </span>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    );
  }
}

export default TabBar;
