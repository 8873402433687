// 通讯录
import instance from "utils/axios";

export function getContacts(params) {
    return instance({
        url: "/contacts",
        params: params
    });
}

export function getPersonInfo(id) {
    return instance({
        url: `/contacts/${id}`
    })
}

export function deletePerson(id) {
    return instance({
        url: `/contacts/${id}`,
        method: "DELETE"
    })
}

export function addPerson(options) {
    return instance({
        url: `/contacts`,
        method: 'POST',
        data: options.data,
    })
}

export function patchPerson(options) {
    return instance({
        url: `/contacts/${options.id}`,
        method: 'PATCH',
        data: JSON.stringify(options.data),
    })
}