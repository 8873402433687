import NavBar from "components/navBar";
import { useNavigate } from "react-router-dom";
import { Picker, ActionSheet, Popup } from "antd-mobile";
import { CheckCircleOutline } from "antd-mobile-icons";
import "./index.scss";
import { useEffect, useState } from "react";
import { getMsg } from "api/msgs";
import SmsTemplate from "views/tabBar/home/smsTemplate";
import { getStorage } from "utils/storage";
import { getTimer } from "utils/index";

function GroupMessage(props) {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [mseeageList, setMessageList] = useState([]);
  const [templateListStatus, setTimeplateListStatus] = useState(false);
  const [templateTypeStatus, setTemplateTypeStatus] = useState(false);
  useEffect(() => {
    getMsg().then((res) => {
      res.content.map((item) => {
        item.createdAt = getTimer(item.createdAt);
        item.successNum = item.to.filter((item) => {
          if (item && item.state) {
            return item.state == 1;
          }
          return false;
        }).length;
        return item;
      });
      console.log(res.content);
      setMessageList(res.content);
    });
    return () => { };
  }, []);
  const sendNavList = [
    {
      title: "模板发送",
      icon: require("images/hpyer/image_06.png"),
      onClick: () => setTimeplateListStatus(true),
    },
    {
      title: "自定义发送",
      icon: require("images/hpyer/image_07.png"),
      onClick: () => navigate("/group_send", { state: { customStatus: true } }),
    },
    // {
    //   title: "语音通知",
    //   icon: require("images/image_01.png"),
    //   onClick: () => { },
    // },
    {
      title: "5G消息发送",
      icon: require("images/image_12.png"),
      onClick: () => {
        // window.location.assign('http://localhost:9527/#/?jwt=' + getStorage());
        window.location.assign('https://yxt.lio8.com/web/#/?jwt=' + getStorage());
        // window.location.assign('https://sxt.liocms.com/xiaoxi/#/?jwt='+getStorage());
      },
    },
  ];
  return (
    <div className="group_message">
      {props.backShow ? null : (
        <NavBar navText="营销推广" backShow={true}></NavBar>
      )}
      <div className="g_message_box">
        <div className="m_box_title">
          {sendNavList.map((item, index) => {
            return (
              <div key={index}>
                <div className="flex_justify" onClick={item.onClick}>
                  <img src={item.icon} alt=""></img>
                  <div>{item.title}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="m_box_cneter">
          <div>任务数据汇总</div>
          <div>
            {/* <div onClick={() => setVisible(!visible)}>
              筛选
              <img src={require("images/hpyer/image_08.png")} alt="" />
            </div> */}
          </div>
        </div>
        <div className="m_box_list">
          {mseeageList.length > 0 ? (
            mseeageList.map((item, index) => {
              return (
                <div
                  className="b_list_Item"
                  key={item.id}
                  onClick={() => {
                    navigate("/group_send", { state: { smsInfo: item } });
                  }}
                >
                  <div style={{ padding: "0.37rem 0.53rem" }}>
                    <div className="l_item_title">
                      <div>
                        {item.template && item.template.id
                          ? "模板发送"
                          : "自定义发送"}
                      </div>
                      <div>
                        {index == 0 ? (
                          <div className="i_title_recent">最近</div>
                        ) : (
                          ""
                        )}
                        {item.state != 1 && item.state != 2 ? (
                          <span style={{ color: "#FF6A6A" }}>
                            {item.state == 0
                              ? "审核中"
                              : item.state == -1
                                ? "发送失败"
                                : ""}
                          </span>
                        ) : (
                          <span style={{ color: "#0EA5FB" }}>
                            {item.state === 1 ? '发送中' : '已发送'}
                          </span> // item.createdAt
                        )}
                      </div>
                    </div>
                    {item.template && item.template.contents ? (
                      <div className="l_item_value">
                        {item.template ? item.template.contents : ""}
                      </div>
                    ) : null}
                  </div>
                  {item.state != 0 ? (
                    <div className="send_num">
                      <div>
                        <img src={require("images/image_02.png")} alt="" />
                        发送条数：{item.to.length}
                      </div>
                      <div>
                        <CheckCircleOutline
                          style={{ marginRight: "0.13rem" }}
                          color="#0EA5FB"
                          fontSize={".43rem"}
                        />
                        已发送：{item.successNum}
                      </div>
                      <div>{item.createdAt}</div>
                    </div>
                  ) : null}
                </div>
              );
            })
          ) : (
            <div className="templist_null">
              <img src={require("images/hpyer/image_14.png")} alt="" />
              <div>您暂未编辑发送过任何短信</div>
              <div>快去发送吧~</div>
              <div onClick={() => setTemplateTypeStatus(true)}>立即发送</div>
            </div>
          )}
        </div>
      </div>
      <Picker
        columns={pickerList}
        visible={visible}
        onClose={() => {
          setVisible(!visible);
        }}
        onConfirm={(v) => {
          console.log(v);
        }}
      />
      <ActionSheet
        visible={templateTypeStatus}
        extra="请选择要发送的类型"
        cancelText="取消"
        actions={[
          {
            text: "模板发送",
            key: 1,
            onClick: () => {
              setTimeplateListStatus(true);
              setTemplateTypeStatus(false);
            },
          },
          {
            text: "自定义发送",
            key: 2,
            onClick: () => {
              navigate("/group_send", { state: { customStatus: true } });
            },
          },
        ]}
        onClose={() => setTemplateTypeStatus(false)}
      ></ActionSheet>
      <Popup
        visible={templateListStatus}
        position="bottom"
        onMaskClick={() => setTimeplateListStatus(false)}
        bodyStyle={{
          minHeight: "30vh",
          maxHeight: "70vh",
          borderTopLeftRadius: "22px",
          borderTopRightRadius: "22px",
        }}
      >
        <SmsTemplate show={props.backShow}></SmsTemplate>
      </Popup>
    </div>
  );
}

const pickerList = [
  [
    { label: "周一", value: "周一" },
    { label: "周二", value: "Tues" },
    { label: "周三", value: "Wed" },
    { label: "周四", value: "Thur" },
    { label: "周五", value: "Fri" },
  ],
];

export default GroupMessage;
