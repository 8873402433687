import "./index.scss";
import { Modal, Radio, Space, Empty } from "antd-mobile";
import { useState, useEffect } from "react";
import {useNavigate} from 'react-router-dom'
import {getContactGroup} from 'api/contactGroup'

function Grouping(props) {
  const navigate = useNavigate()
  const [group, setGroup] = useState("");
  const [groupList, setGroupList] = useState([])
  useEffect(() => {
    getContactGroup().then(res => {
      setGroupList(res.content)
    })
    return () => {
      
    }
  }, [])
  return (
    <Modal
      className="grouping"
      visible={props.show}
      closeOnMaskClick={true}
      title={<div className="grouping_title">选择分组</div>}
      onClose={props.showGrouping}
      showCloseButton={true}
      content={
        <div className="grouping_content">
          {groupList.length > 0 ? (
            <div className="grouping_content">
              <div className="g_content_list">
                <Radio.Group onChange={(e) => setGroup(e)}>
                  <Space direction="vertical">
                    {groupList.map((item, index) => {
                      return (
                        <Radio
                          style={{ "--adm-color-primary": "#0EA5FB" }}
                          key={item.id}
                          value={item}
                        >
                          {item.name}
                        </Radio>
                      );
                    })}
                  </Space>
                </Radio.Group>
              </div>
            </div>
          ) : (
            <Empty description="暂无数据"></Empty>
          )}
          <div className="g_content_add" onClick={() => navigate('/add_group')}>
            <img
              src={require("images/addressBook/image_01.png")}
              alt="创建"
            ></img>
            创建分组
          </div>
        </div>
      }
      actions={[
        {
          key: "confirm",
          text: "确定",
          className: "group_success",
          onClick: () => {
            props.getGruop(group);
          },
        },
      ]}
    ></Modal>
  );
}

export default Grouping;
