import "./index.scss";
import NavBar from "components/navBar";
import React, { Component } from "react";
import axios from "axios";
import { getStorage } from "utils/storage";
import { Dialog, Toast, Modal } from "antd-mobile";

class MyRights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponList: [], // 卷
      rightsNav: [
        "优惠券",
        //  "会员"
      ],
      rightsNavStatus: 0,
      memberList: [
        {
          title: "腾讯视频会员月卡",
          icon: require("images/image_08.png"),
        },
        {
          title: "爱奇艺会员月卡",
          icon: require("images/image_09.png"),
        },
        {
          title: "QQ音乐会员月卡",
          icon: require("images/image_10.png"),
        },
        {
          title: "优酷会员月卡",
          icon: require("images/image_11.png"),
        },
      ],
    };
  }
  componentDidMount() {
    Toast.show({ icon: "loading", content: "加载中...", duration: 0 });
    axios({
      url: "/api2/mymonthcoupon",
      params: { pageNum: 1, pageSize: 10 },
      headers: {
        Authorization: getStorage(),
      },
    })
      .then((res) => {
        Toast.clear();
        if (parseInt(res.data.code) === 0) {
          res.data.data.list = res.data.data.list.map((item) => {
            item.illStatus = false;
            return item;
          });
          console.log(res.data.data.list);
          this.setState({
            couponList: res.data.data.list,
          });
        } else {
          Toast.show({ content: res.data.msg });
        }
      })
      .catch((err) => {
        console.log(err);
        Toast.show({ content: "数据请求失败" });
      });
  }
  render() {
    return (
      <div className="my_rights">
        <NavBar backShow={true} navText="我的权益"></NavBar>
        <div className="rights_nav">
          {this.state.rightsNav.map((item, index) => {
            return (
              <div
                className={
                  (this.state.rightsNavStatus === index ? "r_nav_item_" : "") +
                  " r_nav_item"
                }
                key={index}
                onClick={() => this.setState({ rightsNavStatus: index })}
              >
                {item}
              </div>
            );
          })}
          {/* <div className="right_record">领取记录</div> */}
        </div>
        {this.state.rightsNavStatus === 0 ? (
          <>
            <div className="rights_box">
              {this.state.couponList.map((item) => {
                return (
                  <div className="coupon_item" key={item.id}>
                    <img src={require("images/image_07.png")} alt="" />
                    <div className="c_item_info">
                      <div>
                        <div>{item.cCoupon.couponNumber}</div>
                        <div>
                          <div>{item.cCoupon.couponName}</div>
                          <div
                            onClick={() => {
                              Modal.show({
                                content: (
                                  <>
                                    <div
                                      className="c_item_ill"
                                      dangerouslySetInnerHTML={{
                                        __html: item.cCoupon.couponRm.replace(
                                          /\\n/g,
                                          ""
                                        ),
                                      }}
                                    ></div>
                                    {parseInt(item.cCoupon.couponType) === 2 ? (
                                      <>
                                        <div>
                                          兑换码：
                                          <span
                                            id="copyText"
                                            style={{ color: "#4b7dee" }}
                                          >
                                            {item.cCoupon.couponNumber}
                                          </span>
                                        </div>
                                        <div
                                          className="copy_text"
                                          style={{ marginTop: "30px" }}
                                        >
                                          <div
                                            onClick={() => {
                                              let value =
                                                document.getElementById(
                                                  "copyText"
                                                );
                                              window
                                                .getSelection()
                                                .selectAllChildren(value);
                                              let result =
                                                document.execCommand("Copy");
                                              if (result) {
                                                Toast.show({
                                                  content: "复制成功",
                                                });
                                              }
                                            }}
                                          >
                                            复制兑换码
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                  </>
                                ),
                                closeOnMaskClick: true,
                              });
                            }}
                            style={{ marginRight: ".27rem" }}
                          >
                            立即查看
                          </div>
                        </div>
                        <div>有效期至：{item.cCoupon.couponEnd}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="coupon_new">
              <div
                className="on_click"
                onClick={() => {
                  Dialog.confirm({
                    content: "是否领取卡卷？",
                  })
                    .then((res) => {
                      if (res) {
                        Toast.show({
                          content: "领取中...",
                          icon: "loading",
                          duration: 0,
                        });
                        axios.get("/api2/getmonthcoupon").then((res) => {
                          if (parseInt(res.data.code) === 0) {
                            Toast.show({ content: "领取成功" });
                            this.componentDidMount();
                          } else {
                            Toast.show({ content: res.data.msg });
                          }
                        });
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      Toast.show({ content: "数据请求失败" });
                    });
                }}
                style={{ fontSize: ".4rem" }}
              >
                领取新卷
              </div>
            </div>
          </>
        ) : (
          <div className="rights_box rights_box_">
            {this.state.memberList.map((item, index) => {
              return (
                <div className="box_memben" key={index}>
                  <div className="b_memben_icon">
                    <img src={item.icon} alt="" />
                  </div>
                  <div className="b_memben_title">{item.title}</div>
                  <div className="b_memben_btn">
                    <div
                      onClick={() => {
                        Toast.show({ content: "暂不支持领取" });
                      }}
                      className="on_click"
                    >
                      立即领取
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default MyRights;
