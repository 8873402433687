import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import { getStorage, setStorage } from "utils/storage";
import "utils/flexible";

if (process.env.NODE_ENV === "development") {
  setStorage(
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI1ZmQ2YTFhMjE5NDQ5MzdlM2UyMmZkYTRfMTg2MzY4NDI3MjAiLCJpYXQiOjE2NDA5MTY2MDd9.hCSfGF-E49Og-GwZtXW8QmmmM4RfOHWfd8hUylYsXXc"
  );
}

if (window.location.search) {
  if (!getStorage()) setTimeout(() => window.location.reload(), 1);
  setStorage(window.location.search.split("=")[1]);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
