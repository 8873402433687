import NavBar from "components/navBar";
import {
  Form,
  Input,
  List,
  Picker,
  DatePicker,
  TextArea,
  Toast,
} from "antd-mobile";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.scss";
import { getTimer, uploadImage } from "utils/index";
import Grouping from "components/grouping";
import React, { useState, useEffect } from "react";
import { getPersonInfo, patchPerson, addPerson } from "api/contacts";
import { getGroupContact } from "api/contactGroup";

function AddContent() {
  const { phone, id } = useLocation().state,
    [seePicker, setSeePicker] = useState(false),
    [birPicker, setBirPicker] = useState(false),
    [grouping, setGrouping] = useState(""),
    [showGrouping, setShowGrouping] = useState(false),
    [saveStatus, setSaveStatus] = useState(false),
    [avatar, setAvatar] = useState(require("images/avatar.png")),
    loginRef = React.createRef(),
    avatarRef = React.createRef(),
    navigate = useNavigate();
  useEffect(() => {
    if (id) {
      getPersonInfo(id).then((res) => {
        loginRef.current.setFieldsValue({
          name:
            res.familyName || res.givenName
              ? res.familyName + res.givenName
              : "",
          phone: res.phoneNumbers[0].stringValue,
        });
        // if (res.groupIds) {
        //   getGroupContact({ id: res.groupIds[0] }).then((res) => {
        //     setGrouping(res);
        //   });
        // }
        setSaveStatus(true);
      });
    } else {
      loginRef.current.setFieldsValue({
        phone,
      });
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const saveAddContent = () => {
    if (!saveStatus) return;
    loginRef.current.validateFields().then((res) => {
      if (!res.name) {
        return Toast.show({ content: "请输入姓名" });
      }
      if (id) {
        Toast.show({ icon: "loading", duration: 0 });
        patchPerson({
          id,
          data: {
            givenName: res.name,
            familyName: "",
            phoneNumbers: [{ stringValue: res.phone, name: res.name }],
            groupIds: [grouping.id],
          },
        }).then((res) => {
          Toast.show({ content: "修改成功", icon: "success", duration: 1000 });
          setTimeout(() => {
            navigate(-1, { replace: true });
          }, 1000);
        });
      } else {
        addPerson({
          data: {
            givenName: res.name,
            familyName: "",
            phoneNumbers: [{ stringValue: res.phone, name: res.name }],
          },
        }).then((res) => {
          Toast.show({ content: "添加成功" });
          navigate(-1, { replace: true });
        });
      }
    });
  };
  const uploadImage_ = async () => {
    setAvatar(await uploadImage(avatarRef));
    Toast.show({ content: "上传成功" });
  };
  const getGruop = (data) => {
    setGrouping(data);
    setShowGrouping(false);
  };
  return (
    <div className="add_content">
      <NavBar
        navText={!phone ? "修改联系人信息" : "添加联系人"}
        backShow={true}
      ></NavBar>
      <Form layout="horizontal" style={{ marginTop: ".4rem" }} ref={loginRef}>
        <div className="a_content_info">
          <List padding="0" border="none">
            <List.Item
              prefix={
                <>
                  <img className="c_info_avatar" src={avatar} alt="头像"></img>
                  {/* <input
                    accept="image/*"
                    onChange={uploadImage_}
                    ref={avatarRef}
                    hidden
                    className="avatar_upload"
                    type="file"
                    disabled
                  ></input> */}
                </>
              }
            // extra={false ? "编辑联系人头像" : "头像"}
            >
            </List.Item>
            <Form.Item label="姓名" name="name">
              <Input
                onChange={(e) =>
                  e ? setSaveStatus(true) : setSaveStatus(false)
                }
                placeholder="编辑联系人姓名（必填）"
              ></Input>
            </Form.Item>
            <Form.Item label="号码" name="phone">
              <Input placeholder="请输入你的手机号码"></Input>
            </Form.Item>
            {/* <List.Item
                style={{ "--border-inner": "none" }}
                onClick={() => {
                  setShowGrouping(true);
                }}
                prefix="分组"
                extra={
                  <span
                    style={{
                      color: grouping ? "#333333" : "#C5C5C5",
                    }}
                  >
                    {grouping ? grouping.name : "选择联系人分组"}
                  </span>
                }
              ></List.Item> */}
          </List>
        </div>
        {false ? (
          <>
            <div className="c_info_material">
              <Form.Item
                onClick={() => {
                  setSeePicker(true);
                }}
                label="性别"
                name="sex"
                trigger="onConfirm"
              >
                <Picker
                  title="性别"
                  visible={seePicker}
                  onConfirm={(e) => {
                    setSeePicker(false);
                  }}
                  onCancel={() => setSeePicker(false)}
                  columns={sexList}
                >
                  {(value) =>
                    value[0] ? (
                      value[0].value
                    ) : (
                      <span style={{ color: "#C5C5C5" }}>选择联系人性别</span>
                    )
                  }
                </Picker>
              </Form.Item>
              <Form.Item label="公司名称" name="company">
                <Input placeholder="编辑公司名称"></Input>
              </Form.Item>
              <Form.Item
                onClick={() => {
                  setBirPicker(true);
                }}
                label="生日"
                name="birthday"
                trigger="onConfirm"
              >
                <DatePicker
                  title="生日"
                  visible={birPicker}
                  min={new Date("1972-01-01")}
                  max={new Date()}
                  onClose={() => setBirPicker(false)}
                  precision="day"
                >
                  {(value) =>
                    value ? (
                      <span>{getTimer(value.getTime())}</span>
                    ) : (
                      <span style={{ color: "#C5C5C5" }}>编辑联系人生日</span>
                    )
                  }
                </DatePicker>
              </Form.Item>
            </div>
          </>
        ) : null}

        {/* <div className="c_info_remark">
          <Form.Item label="备注" name="remark" className="inf_remark_input">
            <TextArea placeholder="填写备注信息"></TextArea>
          </Form.Item>
        </div> */}
        <div className="c_info_save">
          <div
            className="on_click"
            style={{ backgroundColor: saveStatus ? "#0EA5FB" : "#C9C9C9" }}
            onClick={saveAddContent}
          >
            确认保存
          </div>
        </div>
      </Form>
      <Grouping
        getGruop={getGruop}
        show={showGrouping}
        showGrouping={() => {
          setShowGrouping(false);
        }}
      ></Grouping>
    </div>
  );
}

const sexList = [
  [
    { label: "男", value: "男" },
    { label: "女", value: "女" },
  ],
];

export default AddContent;
