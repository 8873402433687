import NavBar from "components/navBar";
import { useNavigate, useLocation } from "react-router-dom";
import { Dialog, Toast } from "antd-mobile";
import "./index.scss";
import { getPersonInfo, deletePerson } from "api/contacts";
import { getGroupContact } from "api/contactGroup";
import { useEffect, useState } from "react";

function PersonInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const [personInfo, setPersonInfo] = useState({});
  const [groupList, setGroupList] = useState([]);
  useEffect(() => {
    getPersonInfo(location.state.id).then((res) => {
      setPersonInfo(res);
      // if (res.groupIds.length > 0) {
      //   res.groupIds.map(async (item) => {
      //     getGroupContact({ id: item }).then((res) => {
      //       setGroupList([...groupList, res]);
      //     });
      //   });
      // }
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="person_info">
      <NavBar navText="联系人" backShow={true}></NavBar>
      <div className="p_info_avatar">
        <div className="info_avatar_">
          <img alt="" src={require("images/avatar.png")}></img>
        </div>
        <div className="info_name_">
          {personInfo.familyName + personInfo.givenName || "未知"}
          {/* <img alt="" src={require("images/addressBook/image_04.png")}></img>
          <img alt="" src={require("images/addressBook/image_05.png")}></img> */}
        </div>
      </div>
      <div className="p_infO_phone">
        {personInfo.phoneNumbers
          ? personInfo.phoneNumbers.map((item, index) => {
              return (
                <div className="i_phone_item" key={index}>
                  <div className="flex_justify">
                    <img
                      alt=""
                      src={require("images/addressBook/image_06.png")}
                    ></img>
                  </div>
                  <div>{item.stringValue}</div>
                  <div>{item.name}</div>
                </div>
              );
            })
          : null}
        {/* <div className="i_phone_item">
          <div className="flex_justify">
            <img alt="" src={require("images/addressBook/image_07.png")}></img>
          </div>
          <div style={{ fontSize: ".4rem" }}>江西聚会来责任有限公司</div>
        </div> */}
        {/* <div className="i_phone_item">
          <div className="flex_justify">
            <img alt="" src={require("images/addressBook/image_08.png")}></img>
          </div>
          <div style={{ fontSize: ".4rem" }}>1998.09.29</div>
        </div> */}
        {/* {groupList.length > 0 ? (
          <div className="i_phone_item">
            <div className="flex_justify">
              <img
                alt=""
                src={require("images/addressBook/image_09.png")}
              ></img>
            </div>
            <div style={{ fontSize: ".4rem" }}>
              {groupList.map((item, index) => {
                return <span key={index}>{item.name}</span>;
              })}
            </div>
          </div>
        ) : null} */}
      </div>
      {/* <div
        className="p_infO_phone  p_item_remark"
        style={{ marginTop: "0.27rem" }}
      >
        <div className="i_phone_item" style={{ marginBottom: "0" }}>
          <div className="flex_justify">
            <img alt="" src={require("images/addressBook/image_10.png")}></img>
          </div>
          <div style={{ fontSize: ".4rem" }}>备注（40）</div>
          <div
            onClick={() =>
              navigate("/remark", {
                state: { phone: personInfo.phoneNumbers[0].stringValue },
              })
            }
          >
            立即查看{" "}
            <img alt="" src={require("images/addressBook/image_11.png")}></img>
          </div>
        </div>
        <div className="i_remark_line"></div>
        <div className="i_remark_con">
          作者皆殊列，名声岂浪垂。骚人嗟不见，汉道盛于斯。前辈飞腾入，馀波绮丽为。后贤兼旧列，历代各清规
        </div>
      </div> */}
      {/* <div
        className=" p_infO_phone_  p_item_remark"
        style={{ marginTop: "0.27rem" }}
      >
        <div className="i_phone_item" style={{ marginBottom: "0" }}>
          <div>通讯状况</div>
          <div
            onClick={() =>
              navigate("/call_info", {
                state: { phone: personInfo.phoneNumbers[0].stringValue },
              })
            }
          >
            立即查看
            <img alt="" src={require("images/addressBook/image_11.png")}></img>
          </div>
        </div>
      </div> */}
      <div className="person_oper">
        <div
          className="flex_justify"
          onClick={() =>
            Dialog.confirm({
              content: "是否删除该联系人？",
              onConfirm: async () => {
                await deletePerson(personInfo.id);
                Toast.show({
                  icon: "success",
                  content: "删除成功",
                });
                navigate(-1, { replace: true });
              },
            })
          }
        >
          <img alt="" src={require("images/addressBook/image_12.png")}></img>
          删除联系人
        </div>
        <div
          className="flex_justify"
          onClick={() =>
            navigate("/add_content", { state: { id: personInfo.id } })
          }
        >
          <img alt="" src={require("images/addressBook/image_13.png")}></img>
          编辑联系人
        </div>
      </div>
    </div>
  );
}

export default PersonInfo;
