import React, { useEffect, useState } from "react";
import { Swiper, Toast } from "antd-mobile";
import SmsTemplate from "./smsTemplate";
import "./index.scss";
import { getStorage, removeStorage } from "utils/storage";
import { useNavigate, useLocation } from "react-router-dom";
import { sjmpUrl, gjdxUrl, znjtUrl,wdqyUrl } from "utils/httpUrl";

function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.state) document.documentElement.scrollTop = 337;
    return () => {};
  }, [location.state]);
  return (
    <div className="home_index">
      <img
        alt="首页背景"
        className="home_index_bg"
        src={require("images/home/image_01.png")}
      ></img>
      <div className="h_index_content">
        {/* 轮播图 */}
        <div className="content_carousel">
          <Swiper
            autoplay
            loop
            indicator={() => null}
            className="carousel_swiper"
          >
            {swiperList.map((item, index) => {
              return (
                <Swiper.Item key={index}>
                  <div className="c_swiper_item">
                    <img alt="轮播图" src={item}></img>
                  </div>
                </Swiper.Item>
              );
            })}
          </Swiper>
        </div>
        {/* 导航 */}
        <div className="content_nav">
          <div className="c_nav">
            {navOneList.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {item.show ? (
                    <div
                      key={index}
                      onClick={() => {
                        if (!getStorage()) {
                          Toast.show({
                            content: "您尚未登录，自动跳转至登录页面",
                          });
                          setTimeout(() => {
                            removeStorage();
                            window.location.replace("/");
                          }, 1000);
                          return;
                        }
                        item.routerStatus
                          ? item.path
                            ? navigate(item.path)
                            : Toast.show({ content: item.title })
                          : window.location.assign(item.path);
                      }}
                    >
                      <div>
                        <img src={item.icon} alt={item.title}></img>
                      </div>
                      <div>
                        <span style={{ whiteSpace: "nowrap" }}>
                          {item.title}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </React.Fragment>
              );
            })}
          </div>
          {/* <div className="c_nav">
            {navTwoList.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    item.routerStatus
                      ? item.path
                        ? navigate(item.path)
                        : Toast.show({ content: item.title })
                      : window.location.assign(item.path + getStorage(0));
                  }}
                >
                  <div>
                    <img
                      className="nav_img"
                      src={item.icon}
                      alt={item.title}
                    ></img>
                  </div>
                  <div>
                    <span>{item.title}</span>
                  </div>
                </div>
              );
            })}
          </div> */}
        </div>
        {/* 分类信息 */}
        {/* <div className="content_type">
          <div className="c_type_one">
            {cTypeOne.map((item, index) => {
              return (
                <div key={index}>
                  <span
                    className={
                      cTypeOneStatus === index ? "type_one_status" : ""
                    }
                    onClick={() => {
                      setCTypeOneStatus(index);
                      setTypeChooseList([
                        index,
                        typeChooseList[1],
                        typeChooseList[2],
                      ]);
                    }}
                  >
                    {item}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="c_type_two">
            <CapsuleTabs
              onChange={(e) => {
                setTypeChooseList([typeChooseList[0], e, typeChooseList[2]]);
              }}
              defaultActiveKey="0"
            >
              {cTypeTwo.map((item, index) => {
                return (
                  <CapsuleTabs.Tab
                    title={item}
                    className="type_two_item"
                    key={index}
                  >
                    <Tabs
                      onChange={(e) => {
                        setTypeChooseList([
                          typeChooseList[0],
                          typeChooseList[1],
                          e,
                        ]);
                      }}
                      activeLineMode="fixed"
                      defaultActiveKey="0"
                      className="c_type_three"
                    >
                      {cTypeThree.map((item, index) => {
                        return <Tabs.Tab key={index} title={item}></Tabs.Tab>;
                      })}
                    </Tabs>
                  </CapsuleTabs.Tab>
                );
              })}
            </CapsuleTabs>
          </div>
        </div> */}
        <SmsTemplate></SmsTemplate>
      </div>
    </div>
  );
}

const swiperList = [
  "https://www.sdic.com.cn/cn/rootimages/2020/12/30/16359776.jpg",
  "https://imgcdn.yicai.com/uppics/slides/2019/04/f624084736314207ae5747bbb8e90635.jpg",
  "https://i1.sinaimg.cn/travel/2016/0627/U11604P704DT20160627144216.png",
];

const navTwoList = [
  {
    title: "套餐查询",
    path: "/combo_see",
    routerStatus: true,
    icon: require("images/home/image_10.png"),
    show: false,
  },
  {
    title: "短信查询",
    path: "",
    routerStatus: true,
    icon: require("images/home/image_11.png"),
    show: false,
  },
  {
    title: "短信订购",
    path: "",
    routerStatus: true,
    icon: require("images/home/image_12.png"),
    show: false,
  },
  {
    title: "我的权益",
    path: wdqyUrl,
    icon: require("images/home/image_13.png"),
    show: true,
  },
];
const navOneList = [
  {
    title: "AI接线员",
    path: znjtUrl,
    icon: require("images/home/image_02.png"),
    show: false,
  },
  {
    title: "手机名片",
    path: sjmpUrl,
    icon: require("images/home/image_03.png"),
    show: true,
  },
  {
    title: "挂机通知",
    path: gjdxUrl,
    icon: require("images/home/image_04.png"),
    show: true,
  },
  {
    title: "视频推广",
    path: "/add_video_info",
    routerStatus: true,
    icon: require("images/home/image_05.png"),
    show: false,
  },
  ...navTwoList,
];

export default Home;
