import { Dialog, Toast } from "antd-mobile";
import axios from "axios";
import NavBar from "components/navBar";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getStorage } from "utils/storage";
import "./index.scss";

export function TemplateSet() {
  const { content } = useLocation().state,
    [itemStatus, setItemStatus] = useState(0),
    navigate = useNavigate();
  return (
    <div className="template_set">
      <NavBar backShow={true} navText="设置短信"></NavBar>
      <div className="line"></div>
      <div className="template_vaue">
        <div>短信内容</div>
        <div>{content}</div>
      </div>
      <div className="line"></div>
      <div className="template_num">
        <div>设置发送频率</div>
        <div className="t_num_list">
          {template.map((item, index) => {
            return (
              <div
                className={
                  (itemStatus === index ? "c_list_item_" : "") + " c_list_item"
                }
                key={index}
                onClick={() => setItemStatus(index)}
              >
                {item}
              </div>
            );
          })}
          <div className="lisnt_remark">
            备注： 频次是指向同一个号码发送的频次。
          </div>
        </div>
      </div>
      <div className="template_btn">
        <div
          className="button_ on_click"
          onClick={() => {
            Dialog.confirm({
              content: "是否提交该短信内容？",
              onConfirm: async () => {
                await axios({
                  url: `https://zhineng.lio8.com/1/api/toemail?token=${getStorage()}`,
                  method: "POST",
                  params: {
                    title: "模板",
                    content: template[itemStatus] + "——" + content,
                  },
                })
                  .then(async (res) => {
                    if (res.data.code == 0) {
                      Toast.show({
                        content: "提交成功",
                        icon: "success",
                        maskClickable: false,
                        afterClose: () => navigate(-1, { replace: true }),
                      });
                    } else {
                      Toast.show({
                        content: "提交失败",
                        icon: "fail",
                        maskClickable: false,
                      });
                    }
                  })
                  .catch((err) => {
                    Toast.show({ content: "数据请求失败，请稍后重试" });
                  });
              },
            });
          }}
        >
          立即发送
        </div>
      </div>
    </div>
  );
}
const template = ["一天一次", "一周一次", "一月一次", "每次都发"];
