import { Form, Input, Toast } from "antd-mobile";
import axios from "axios";
import NavBar from "components/navBar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { uploadImage } from "utils";
import { getStorage } from "utils/storage";
import "./index.scss";

export function AddInfo() {
  const formRef = React.createRef(),
    [licenseImg, setLicenseImg] = useState(""),
    [authorizeFile, setAuthorizeFile] = useState(""),
    imageRef = React.createRef(),
    fileRef = React.createRef(),
    navigate = useNavigate();
  useEffect(() => {
    return () => {
      Toast.clear();
    };
  });
  return (
    <div className="add_info">
      <NavBar navText="填写信息" backShow={true}></NavBar>
      <div className="line"></div>
      <div className="info_box">
        <Form ref={formRef} layout="vertical">
          <Form.Item
            label={
              <div className="from_title">
                联系人<span>*</span>
              </div>
            }
            name="name"
          >
            <Input placeholder="请输入联系人姓名"></Input>
          </Form.Item>
          <Form.Item
            label={
              <div className="from_title">
                联系号码<span>*</span>
              </div>
            }
            name="phone"
          >
            <Input placeholder="请输入联系号码"></Input>
          </Form.Item>
          <Form.Item
            label={
              <div className="from_title">
                公司名称<span>*</span>
              </div>
            }
            name="company"
          >
            <Input placeholder="请输入公司名称"></Input>
          </Form.Item>
          <Form.Item
            label={
              <div className="from_title">
                企业营业执照<span>*</span>
              </div>
            }
          >
            <div className="license">
              <div className="license_img">
                <input
                  className="l_img_input"
                  type="file"
                  multiple="multiple"
                  // capture="camera"
                  ref={imageRef}
                  accept="image/*"
                  onChange={async () => {
                    setLicenseImg(await uploadImage(imageRef));
                  }}
                ></input>
                {licenseImg ? (
                  <img className="img_input_1" src={licenseImg} alt="" />
                ) : (
                  <img
                    className="img_input_2"
                    src={require("images/image_04.png")}
                    alt=""
                  />
                )}
              </div>
              <div className="license_title">
                {licenseImg ? "" : "请上传企业营业执照"}
              </div>
            </div>
          </Form.Item>

          <Form.Item
            label={
              <div className="from_title">
                授权文件<span>*</span>
              </div>
            }
          >
            <div className="authorize">
              <img src={require("images/image_05.png")} alt="" />
              <div>
                {authorizeFile ? (
                  authorizeFile.name
                ) : (
                  <span>请添加授权文件</span>
                )}
              </div>
              <input
                type="file"
                ref={fileRef}
                accept=".doc,.docx,.xml,application/*,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.txt"
                onChange={async () => {
                  const file = await uploadImage(fileRef);
                  setAuthorizeFile({
                    file,
                    name: fileRef.current.files[0].name,
                  });
                }}
              ></input>
            </div>
          </Form.Item>
        </Form>
      </div>
      <div className="line"></div>
      <div className="introduce">
        <div className="int_title">商品介绍</div>
        <div className="int_value">
          <p>
            视频推广是一款为企业形象/产品推广，个人个性化
            展示的平台。设置完成后，拨打商信通用户的手机
            时，主叫方将看到该用户设置的个性化视频。
          </p>
          <p>
            设置方法：按照下表提交所需要的相关信息，我们将
            在三个工作日内联系您。
          </p>
        </div>
      </div>
      <div className="add_btn">
        <div
          className="on_click"
          onClick={async () => {
            const formData = await formRef.current.validateFields();
            for (const i in formData) {
              if (!formData[i])
                return Toast.show({ content: "请完善信息后提交！" });
            }
            if (!licenseImg || !authorizeFile)
              return Toast.show({ content: "请完善信息后提交！" });
            Toast.show({
              icon: "loading",
              content: "提交中...",
              duration: 0,
              maskClickable: false,
            });
            axios({
              url: `https://zhineng.lio8.com/1/api/toemail?token=${getStorage()}`,
              method: "POST",
              params: {
                title: "视频推广",
                content: `姓名：${formData.name}——手机号码：${formData.phone}——公司名称：${formData.company}——营业执照：${licenseImg}——授权文件：${authorizeFile.file}`,
              },
            })
              .then((res) => {
                if (res.data.code == 0) {
                  Toast.show({
                    content: "提交成功",
                    icon: "success",
                    maskClickable: false,
                    afterClose: () => navigate(-1, { replace: true }),
                  });
                } else {
                  Toast.show({
                    content: "提交失败",
                    icon: "fail",
                    maskClickable: false,
                  });
                }
              })
              .catch((err) => {
                Toast.show({ content: "数据请求失败，请稍后重试" });
              });
          }}
        >
          立即提交
        </div>
      </div>
    </div>
  );
}
