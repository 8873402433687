import React from "react";
import GuideCss from "./index.module.scss";
import { loginUrl } from "utils/httpUrl";

function Guide() {
  return (
    <div className={GuideCss.guide}>
      <img
        className={GuideCss.guide_we}
        alt="欢迎使用商信通"
        src={require("images/guide/image_01.png")}
      ></img>
      <div className={GuideCss.guide_hint}>为用户带来跟优质的通讯服务</div>
      <img
        className={GuideCss.guide_lo}
        alt="logo"
        src={require("images/guide/image_02.png")}
      ></img>
      <div className="button_" onClick={() => {window.location.assign(loginUrl)}}>
        手机号登录 
        {/* https://app.dasongnet.com/app/?url=https://sxt.liocms.com/ */}
      </div>
    </div>
  );
}

export default Guide;
