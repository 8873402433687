import NavBar from "components/navBar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProfileList from "./profileList";
import { List, Toast, Collapse } from "antd-mobile";
import "./index.scss";
import { gjdxUrl, sjmpUrl } from "utils/httpUrl";
import jwtDecode from "jwt-decode";
import { getStorage, removeStorage } from "utils/storage";
import axios from "axios";

function Profile() {
  const [pushReminder, setPushReminder] = useState(pushReminder_);
  const [phone, setPhone] = useState("");
  const [phoneArea, setPhoneArea] = useState("未知");
  useEffect(() => {
    if (!getStorage()) {
      Toast.show({
        content: "您尚未登录，自动跳转至登录页面",
      });
      setTimeout(() => {
        removeStorage();
        window.location.replace("/");
      }, 1000);
      return;
    }
    const phone_ = jwtDecode(getStorage()).sub.split("_")[1];
    setPhone(phone_);
    axios.get(`/api3/phonearea.php?number=${phone_}`).then((res) => {
      const { province, city, sp } = res.data.data;
      setPhoneArea(province + city + " " + sp);
    });
    return () => { };
  }, []);
  const checkedClick = (index) => {
    // let _pushReminder_ = pushReminder;
    // _pushReminder_[index].checked = !_pushReminder_[index].checked;
    // setPushReminder([..._pushReminder_]);
  };
  return (
    <div className="profile">
      <div className="profile_bg"></div>
      <NavBar navText="个人中心" textColor="#ffffff" bgColor="#0ea5fb"></NavBar>
      <div className="profile_info">
        <div className="p_info_one">
          <img src={require("images/avatar.png")} alt="头像"></img>
        </div>
        <div className="p_info_two">
          <div>
            <div>{Pone(phone)}</div>
            <div>{phoneArea}</div>
          </div>
        </div>
        <div className="p_info_three flex_justify">
          <Link to={"/"} replace>
            <div>退出登录</div>
          </Link>
        </div>
      </div>
      <div className="profile_list">
        {profileList.map((item, index) => {
          return <ProfileList list={item} key={index}></ProfileList>;
        })}
      </div>{" "}
      <div className="problem_list">
        <Collapse>
          {problemList().map((item, index) => {
            return (
              <Collapse.Panel key={index} title={item.title}>
                <div className="problem_list_value">
                  <div dangerouslySetInnerHTML={{ __html: item.value }}></div>
                  {item.img ? <img src={item.img}></img> : null}
                </div>
              </Collapse.Panel>
            );
          })}
        </Collapse>
      </div>
      {/* <div className="push_reminder">
        <div className="push_title">推送提醒设置</div>
        <List
          className="push_list"
          style={{ "--border-bottom": "none", "--border-top": "none" }}
        >
          {pushReminder.map((item, index) => {
            return (
              <List.Item
                className="push_list_"
                prefix={<img src={item.icon} alt={item.title}></img>}
                key={index}
                extra={
                  <div
                    className="push_checked"
                    onClick={() => {
                      checkedClick(index);
                    }}
                  >
                    <div
                      className={`${item.checked ? "checked_status" : null
                        } checked_one`}
                    >
                      <div
                        className={`${item.checked ? "checked_status_" : null
                          } checked_two`}
                      ></div>
                    </div>
                  </div>
                }
              >
                {item.title}
              </List.Item>
            );
          })}
        </List>
      </div> */}
    </div>
  );
}

const problemList = () => {
  let problemList = [{
    title: "什么是“商信通”？",
    value:"答：商信通是一款为个人及小微商户提供个人通讯增强服务的应用平台，主要为用户提供云通讯录（CRM）、来电保护等功能，协助用户高效管理客户信息，保障用户的通话安全。",
  }, {
    title: "如何办理“商信通”业务？",
    value: "答：您可以通过地市客户经理、营业厅、10010热线等渠道办理。",
    }, {
    title: "服务范围",
    value: "上海联通移网用户中有客户管理需求的企业或商户，以及重视来话的商务人士等。",
    }, {
    title: "产品资费标准是什么？",
    value: "答：各套餐包月功能费参见下表：",
    img: require("images/image_03.jpg")
    }, {
    title: "来电保护需要安装APP或其他设置吗？",
    value: "答：来电保护是系统根据用户来电自动匹配，通过闪信方式提醒用户，用户无需安装APP或设置即可使用。",
    }, {
    title: "权益活动开始时间和结束时间？",
    value: "答：活动时间2023年4月1日-2023年12月31日。",
    }, {
    title: "用户领取的会员权益有没有期限？",
    value: "答：活动期内成为商信通会员且业务持续续订，可获得连续12个月会员权益，每月仅可领取1次权益产品，每次领取1款。<br/>业务退订生效，12个月会员权益即使未到期也会立即终止。<br/>12个月会员权益期内，如某月未领取权益，视为自动放弃领取资格，会员资格不予延期。<br/>领取的权益产品使用期限，以领取成功通知为准。",
    }, {
    title: "（六选一）会员权益如何领取？",
    value: "答：登录商信通网页后，进入超级通讯-我的权益页面进行领取。",
    }, {
    title: "10元话费权益领取后如何生效？",
    value: "答：10元话费权益领取后，商信通支撑厂家将于48小时内通过联通网上营业厅充值的方式，直接充值至用户的订购号码上（充值号码仅限商信通订购号码，其他号码不予充值），无需用户操作，充值到帐后将会短信通知用户。",
    }, {
    title: "更多服务问题的解决方式？",
    value: "答：客户更多问题可拨打本业务客服热线4006996650进行人工咨询。",
    }];

  return problemList;
};

const Pone = (phone) => phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");

const profileList = [
  // [
  //   {
  //     title: "套餐情况",
  //     icon: require("images/profile/image_01.png"),
  //     path: "",
  //     routerStatus: true,
  //   },
  //   {
  //     title: "短信条数使用情况",
  //     icon: require("images/profile/image_02.png"),
  //     path: "",
  //     routerStatus: true,
  //   },
  //   {
  //     title: "短信条数购买",
  //     icon: require("images/profile/image_03.png"),
  //     path: "",
  //     collapse: true,
  //     children: [
  //       {
  //         title: "群发",
  //         icon: require("images/profile/image_08.png"),
  //         path: "",
  //         routerStatus: true,
  //       },
  //       {
  //         title: "挂机短信",
  //         icon: require("images/profile/image_09.png"),
  //         path: gjdxUrl,
  //       },
  //       {
  //         title: "手机名片",
  //         icon: require("images/profile/image_10.png"),
  //         path: sjmpUrl,
  //       },
  //     ],
  //   },
  //   {
  //     title: "增值服务",
  //     icon: require("images/profile/image_04.png"),
  //     path: "",
  //     routerStatus: true,
  //   },
  // ],
  [
    // {
    //   title: "退订服务",
    //   icon: require("images/profile/image_05.png"),
    //   path: "",
    //   routerStatus: true,
    // },
    {
      title: "常见问题",
      icon: require("images/profile/image_06.png"),
      path: "/common_problem",
      routerStatus: true,
    },
    // {
    //   title: "客服",
    //   icon: require("images/profile/image_07.png"),
    //   path: "tel://18270354991",
    //   routerStatus: false,
    // },
  ],
];

const pushReminder_ = [
  // {
  //   title: "云通讯录陌生号码维护提醒",
  //   icon: require("images/profile/image_11.png"),
  //   checked: false,
  // },
  // {
  //   title: "智能秘书接听待接提醒",
  //   icon: require("images/profile/image_12.png"),
  //   checked: true,
  // },
];

export default Profile;
