import { Input, Toast } from "antd-mobile";
import NavBar from "components/navBar";
import React, { useState } from "react";
import { uploadImage } from "utils";
import "./index.scss";
import { addContactGroup } from "api/contactGroup";
import { useNavigate } from "react-router-dom";

function AddGroup() {
  const avatarRef = React.createRef();
  const [groupAvatar, setGroupAvatar] = useState(require("images/avatar.png")),
    [groupName, setGroupName] = useState(""),
    navigate = useNavigate();
  const uploadImage_ = async () => {
    setGroupAvatar(await uploadImage(avatarRef));
  };
  return (
    <div className="add_group">
      <NavBar backShow={true} navText="创建分组"></NavBar>
      <div className="a_group_avatar flex_justify">
        <div className="g_avatar_box">
          <img src={groupAvatar} alt="分组头像"></img>
          <input
            onChange={() => uploadImage_()}
            ref={avatarRef}
            type="file"
            accept="image/*"
          ></input>
        </div>
      </div>
      <div className="g_avatar_hint">上传分组头像</div>
      <div className="g_group_name">
        <div className="flex_justify">
          <Input
            onChange={(e) => {
              setGroupName(e);
            }}
            placeholder="请输入分组名称"
          ></Input>
        </div>
      </div>
      <div className="group_save">
        <div
          onClick={() => {
            if (!groupName) return Toast.show({ content: "请输入分组名称" });
            addContactGroup({ data: { name: groupName } }).then((res) => {
              Toast.show({
                content: "添加成功",
                icon: "success",
              });
              setTimeout(() => navigate(-1, { replace: true }), 100);
            });
          }}
        >
          保存
        </div>
      </div>
    </div>
  );
}

export default AddGroup;
