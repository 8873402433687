import React from "react";
import NavBar from "components/navBar";
import { mobile } from "utils/regExp";
import PubSub from "pubsub-js";
import { Button, Form, Input, Toast } from "antd-mobile";
import Navigate from "route/useNavigate";

import "./index.scss";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.loginForm = React.createRef();
    this.time = () => {}
    this.state = {
      codeText: "获取验证码",
    };
  }

  componentWillUnmount() {
    clearInterval(this.time)
  }

  getCode = () => {
    if (this.state.codeText !== "获取验证码") return;
    this.loginForm.current
      .validateFields(["phone"])
      .then((res) => {
        Toast.show({
          content: "验证码发送成功，请注意查收！",
        });
        let codeNum = 60;
        this.setState({ codeText: `${codeNum} s` });
        this.time = setInterval(() => {
          if (codeNum === 1) {
            clearInterval(this.time);
            return this.setState({ codeText: "获取验证码" });
          }
          codeNum--;
          this.setState({ codeText: `${codeNum} s` });
        }, 1000);
      })
      .catch();
  };

  phoneLogin = () => {
    this.loginForm.current.validateFields().then((res) => {
      const { phone, code } = res;
      console.log(phone, code);
      PubSub.publish("navigate", { type: 2, path: "/home" });
    });
  };

  render() {
    return (
      <div className="login">
        <NavBar bgColor="ffffff00" backColor="#ffffff" backShow={true}></NavBar>
        <img
          alt="登录背景"
          className="login_bg"
          src={require("images/login/image_01.png")}
        ></img>
        <div className="login_from">
          <div className="login_from_box">
            <div className="l_from_title">手机号码登录</div>
            <Form ref={this.loginForm}>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(mobile),
                    message: "请输入正确格式的手机号码！",
                  },
                ]}
              >
                <div className="from_input">
                  <div>
                    <img
                      alt="手机图标"
                      src={require("images/login/image_02.png")}
                    ></img>
                  </div>
                  <Input
                    type="number"
                    className="from_input_input"
                    placeholder="请输入手机号"
                  ></Input>
                </div>
              </Form.Item>
              <Form.Item
                name="code"
                rules={[{ required: true, message: "请输入验证码!" }]}
              >
                <div className="from_input">
                  <div>
                    <img
                      alt="验证码图标"
                      src={require("images/login/image_03.png")}
                      className="code_icon"
                    ></img>
                  </div>
                  <Input
                    className="from_input_input"
                    type="number"
                    placeholder="请输入手机号验证码"
                  ></Input>
                </div>
              </Form.Item>
              <div className="get_code">
                <span onClick={this.getCode}>{this.state.codeText}</span>
              </div>
              <Button onClick={this.phoneLogin} className="button_">
                <Navigate>立即登录</Navigate>
              </Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
