import axios from "axios";
import { Toast } from "antd-mobile";
import { getStorage, removeStorage } from "./storage";

const instance = axios.create({
  baseURL: "https://api.dasongnet.com/cobra",
  timeout: 100000,
});
// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    if (!getStorage()) {
      Toast.show({ content: "登录验证失败，请重新登录" });
      setTimeout(() => {
        removeStorage();
        window.location.replace("/");
      }, 1000);
      return;
    }
    config.headers = {
      Authorization: "Bearer " + getStorage(),
      "content-type": "application/json",
    };
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if (response.status == 200) return response.data;
    console.log(response);
    Toast.show({ content: "数据请求失败" });
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default instance;
