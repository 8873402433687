import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { baseRouter } from "./modules/baseRouter";
import { callHistoryRoute } from "./modules/callHistoryRouter";
import { addressBookRouter } from "./modules/addressBookRouter";
import { cyperRouter } from "./modules/cyperRouter";

const router = [
  ...baseRouter,
  ...callHistoryRoute,
  ...addressBookRouter,
  ...cyperRouter,
];

const RouterIndex = (props) => {
  return (
    <Router>
      <Routes>
        {router.map((route, index) => {
          return (
            <Route exact key={index} path={route.path} element={route.element}>
              {route.children
                ? route.children.map((route, index) => {
                    return childrenRoute(route, index);
                  })
                : null}
            </Route>
          );
        })}
      </Routes>
    </Router>
  );
};

function childrenRoute(route, index) {
  return (
    <Route exact key={index} path={route.path} element={route.element}>
      {route.children
        ? route.children.map((route, index) => {
            return childrenRoute(route, index);
          })
        : null}
    </Route>
  );
}

export default RouterIndex;
