import { Toast } from "antd-mobile";
import axios from "axios";

//获取当前年月日星期几几点几分几秒并打印
export function getTimer(t) {
    var date = new Date(t ? t : "");
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var datee = date.getDate();
    var time = year + "年" + month + "月" + datee + "日";
    return time;
}

//获取当前年月日星期几几点几分几秒并打印
export function getTimer_(time_) {
    var date = new Date(time_);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var datee = date.getDate();
    var time = year + "-" + month + "-" + datee + "	";

    function getTimerr() {
        var time = new Date(time_);
        var hour = time.getHours();
        hour = hour < 10 ? "0" + hour : hour;
        var minute = time.getMinutes();
        minute = minute < 10 ? "0" + minute : minute;
        var second = time.getSeconds();
        second = second < 10 ? "0" + second : second;
        return hour + ":" + minute + ":" + second;
    }
    return time + getTimerr();
}

export function uploadImage(fileRef) {
    return new Promise((resolve) => {
        // let image = fileRef.current.files[0];
        // const reader = new FileReader();
        // reader.readAsDataURL(image);
        // reader.onload = (e) => {
        //   resolve(e.target.result);
        // };
        Toast.show({
            content: "上传中...",
            duration: 0,
            icon: "loading",
            maskClickable: false,
        });
        const formData = new FormData();
        formData.append("file", fileRef.current.files[0]);
        axios({
            url: `https://zhineng.lio8.com/1/common/upload`,
            method: "POST",
            data: formData,
        }).then((res) => {
            if (res.data.code == 0) {
                resolve(res.data.url);
            } else {
                Toast.show({ content: "上传失败" });
            }
        });
    });
}