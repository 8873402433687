import React, { useEffect, useState } from "react";
import { SetOutline, LocationOutline } from "antd-mobile-icons";
import { useNavigate } from "react-router-dom";
import { CapsuleTabs, Tabs, Toast } from "antd-mobile";
import { getStorage, removeStorage } from "utils/storage";
import "./index.scss";
function SmsTemplate(props) {
  const navigate = useNavigate();
  const [cTypeOneStatus, setCTypeOneStatus] = useState(0);
  const [typeChooseList, setTypeChooseList] = useState([0, 0, 0]);
  useEffect(() => {
    if (props.show) {
      setCTypeOneStatus(1);
      setTypeChooseList([1, 0, 0]);
    }
    return () => { };
  }, []);
  const setTemplate = (content, num) => {
    let templateValue = content;
    for (let i = 1; i <= num; i++) {
      templateValue = templateValue.replace(
        "***",
        `<span style="color: #0EA5FB">【自定义${i}】</span>`
      );
    }
    return templateValue;
  };
  return (
    <>
      <div className="content_type">
        <div className="c_type_one">
          {cTypeOne.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {props.show && index === 0 ? null : (
                  <div key={index}>
                    <span
                      className={
                        cTypeOneStatus === index ? "type_one_status" : ""
                      }
                      onClick={() => {
                        setCTypeOneStatus(index);
                        setTypeChooseList([index, typeChooseList[1], 0]);
                      }}
                    >
                      {item}
                    </span>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
        <div className="c_type_two">
          <CapsuleTabs
            onChange={(e) => {
              setTypeChooseList([typeChooseList[0], e, 0]);
            }}
          // defaultActiveKey="0"
          >
            {cTypeTwo[0].map((item, index) => {
              return (
                <CapsuleTabs.Tab
                  title={item}
                  className="type_two_item"
                  key={index}
                >
                  <Tabs
                    onChange={(e) => {
                      setTypeChooseList([
                        0,
                        1,
                        e,
                      ]);
                    }}
                    activeLineMode="fixed"
                    className="c_type_three"
                    activeKey={typeChooseList[2] + ""}
                  >
                    {cTypeThree[1].map((item, index) => {
                      return (
                        <Tabs.Tab
                          key={index + ""}
                          title={item.title}
                        ></Tabs.Tab>
                      );
                    })}
                  </Tabs>
                </CapsuleTabs.Tab>
              );
            })}
          </CapsuleTabs>
        </div>
      </div>
      <div className="sms">
        {cTypeThree[1][typeChooseList[2]].content.map(
          (item, index) => {
            return (
              <div key={index}>
                <div className="sms_item">
                  <div className="sms_title">
                    <div>{index == 0 && typeChooseList[2] == 2 ? '体验模板' : ''}</div>
                    <div>
                      {(
                        <div className="flex_justify">
                          <LocationOutline />
                          <span
                            onClick={() => {
                              if (!getStorage()) {
                                Toast.show({
                                  content: "您尚未登录，自动跳转至登录页面",
                                });
                                setTimeout(() => {
                                  removeStorage();
                                  // window.location.replace("/");
                                  window.location.replace("/#/guide");
                                  window.location.reload();
                                }, 1000);
                                return;
                              }
                              navigate("/group_send", {
                                state: {
                                  id: item.id,
                                  content: item.content,
                                  input: item.input,
                                },
                              });
                            }}
                          >
                            我要发送
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="sms_value">
                    {
                      <div
                        dangerouslySetInnerHTML={{
                          __html: setTemplate(item.content, item.input),
                        }}
                      ></div>
                    }
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    </>
  );
}

// const cTypeOne = ["模板设置", "营销推广"];
const cTypeOne = ["营销推广"];

const cTypeTwo = [["短信推广"], ["挂机模板"]];

const cTypeThree = [
  [
    {
      title: "商务推广",
      content: [
        "感谢您对本公司的支持！您的满意是我们的服务宗旨！微信同号，欢迎指导，谢谢！",
        "感谢您的来电，小店近期将推出促销活动，电话微信同号，欢迎咨询活动详情！",
        "感谢您对本公司的支持！我们将以创新的产品，诚信的服务，期待与您的合作！微信同号。",
        "感谢您的来电，您的满意是我们的不懈追求，我们将竭诚为您提供优质的服务！",
        "感谢您的来电，祝您身体健康、工作顺利！",
      ],
    },
    {
      title: "节日祝福",
      content: [
        "钟声敲醒萌芽的爱情，微风透露春来的讯息。2月14日情人节，连空气都是甜蜜的气味。",
        "愿我心爱之人，百岁无忧。",
        "最美的年华，不伦年龄，不伦经历，有你，足矣。214情人节快乐!",
        "团团圆圆每一年，平平安安每一天！元宵节快乐。",
        "十五的月儿圆又圆，给您全家拜个年！家好运好人也好，幸福美满更团圆！",
      ],
    },
    {
      title: "展现自我",
      content: [
        "不忘初心，牢记使命。",
        "微笑拥抱每一天，做像向日葵般温暖的女子。",
        "奔忙中，你来我往，遇见的都是缘分。茫茫人海，一生当中的擦肩相遇少之可怜，更何况相识与相知。",
        "遇到困难时不要抱怨，既然改变不了过去，那么就努力改变未来。",
        "我们什么都没有，唯一的本钱就是青春。梦想让我与众不同，奋斗让我改变命运!] ",
      ],
    },
    {
      title: "暖暖心意",
      content: [
        "近期变天，当心着凉；注意身体，吃么么香；凉天添衣，少吃冰棒；吃香睡香，身体倍棒！",
        "优秀的团队能塑造出优秀团队文化，优秀的团队文化能酿造出优秀的人。",
        "可能我给不了你世界上最好的一切，可是我一定会把我最好的一切给你。",
        "世上最幸福的事莫过于她在闹，你在笑，如此温暖一生，平凡而不平庸。",
        "我会用最初的心，陪你走最远的路。	",
      ],
    },
  ],
  [
    {
      title: "会员通知",
      content: [
        {
          id: "62092f3c75fa022db076d610",
          content:
            "***邀您参加老用户线下分享会，现场为您准备了精美茶歇、丰富好礼分享。活动地址：***诚挚期待您的到来",
          input: 2,
        },
        {
          id: "62092f4775fa022db076d611",
          content:
            "尊敬的新老会员，即日起可前来本店兑换积分礼品，积分兑换活动于***月***日结束，烦请大家相互转告。谢谢！地址:***",
          input: 3,
        },
        {
          id: "62092f4d75fa022db076d612",
          content:
            "本店促销活动；会员充值回馈，现充值***返***元钜惠，名额有限，送完为止，微信同号，更多详情进店或加微办理，地址：***",
          input: 3,
        },
        {
          id: "62092f5275fa022db076d613",
          content:
            "***（618/双十一/年中）狂欢！夏季新品***折起，超千款直降低至*折起，专区***件***折。速加微信咨询（微信同号）。",
          input: 5,
        },
        {
          id: "62092f5875fa022db076d614",
          content:
            "**周年店庆感恩活动，***于**日进行，凭此短信可获***，并有机会获得礼品一份，数量有限。地址：****，电话***。",
          input: 6,
        },
      ],
    },
    {
      title: "客户关怀",
      content: [
        {
          id: "62092fbf75fa022db076d615",
          content:
            "尊敬的客户，在您生日之际，我公司全体员工衷心祝愿您平安顺利、健康顺意!我们为您准备了***，本月恭候您来店领取。",
          input: 1,
        },
        {
          id: "",
          content:
            "尊敬的会员您好：感谢您长期以来对本店的信任和支持，为此特意为你准备了精美小礼品一份，您可空闲时到本店领取，地址：***",
          input: 1,
        },
        {
          id: "",
          content:
            "尊敬的客户，针对我们**4S店的老客户，为确保您的假期出行行车安全，特为您提供一次免费检测，名额有限，先到先得哟！",
          input: 1,
        },
        {
          id: "",
          content:
            "雨雪天路滑，行驶时注意安全，准备驾车外出的朋友注意检查制动系统和雨刮器是否工作正常，祝您一路平安！",
          input: 0,
        },
        {
          id: "",
          content:
            "您好!***全员已经在线办公!您可以通过电话，微信等联系我们。疫情当前，生活向前。只隔离病毒，不隔离服务!所有***，***，***维护一切恢复!***祝您和您的家人身体健康!做好防护!",
          input: 5,
        },
      ],
    },
    {
      title: '5G消息',
      content: [
        {
          id: '63c9279e99f9e8225c71880a',
          content: '祝您和家人幸福美满，生活愉快！',
          input: 0,
        },
        // {
        //   id: '63c9279e99f9e8225c71880a',
        //   content: '祝您及家人身体健康，事事顺心！',
        //   input: 0,
        // },
        // {
        //   id: '63c9279e99f9e8225c71880a',
        //   content: '祝身体健康，阖家欢乐！',
        //   input: 0,
        // },
        // {
        //   id: '63c9279e99f9e8225c71880a',
        //   content: '祝您身体健康、工作顺利！',
        //   input: 0,
        // },
      ],
    },
  ],
];

export default SmsTemplate;
