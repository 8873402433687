// 分组
import instance from "utils/axios";

export function getContactGroup() {
  return instance({
    url: "/contactGroups",
  });
}

export function addContactGroup(options) {
  return instance({
    url: "/contactGroups",
    method: "POST",
    data: options.data,
  });
}

export function getGroupContact(options) {
  return instance({
    url: `/contactGroups/${options.id}`,
  });
}
