import { useNavigate } from "react-router-dom";
import PubSub from "pubsub-js";
import { Toast } from "antd-mobile";
import React, { useEffect } from "react";

const Navigate = (props) => {
  const navigate = useNavigate();
  // 模拟生命周期
  useEffect(() => {
    PubSub.subscribe("navigate", (msg, data) => {
      switch (data.type) {
        case 0: // back
          navigate(-1, { replace: true });
          break;
        case 1: // push
          navigate(data.path);
          break;
        case 2: //replace
          navigate(data.path, { replace: true });
          break;
        default:
          Toast.show({
            content: "跳转失败",
          });
          break;
      }
    });
    // 销毁
    return () => {
      PubSub.unsubscribe("navigate");
    };
  });

  return <div>{props.children}</div>;
};

export default Navigate;
