import Remark from "views/addressBook/remark";
import AddContent from "views/addressBook/addContent";
import AddGroup from "views/addressBook/addGroup";
import GroupList from "views/addressBook/groupList";
import AddGroupPerson from "views/addressBook/addGroupPerson";
import PersonInfo from "views/addressBook/personInfo";

export const addressBookRouter = [
  {
    path: '/remark',
    element: <Remark></Remark>
  },
  {
    path: '/add_content',
    element: <AddContent></AddContent>
  },
  {
    path: '/add_group',
    element: <AddGroup></AddGroup>
  },
  {
    path: '/group_list',
    element: <GroupList></GroupList>,
  },
  {
    path: '/add_group_person',
    element: <AddGroupPerson></AddGroupPerson>
  },
  {
    path: '/person_info',
    element: <PersonInfo></PersonInfo>
  }
]