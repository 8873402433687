import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from "components/navBar";
import { getTimer_ } from "utils/index";
import "./index.scss";

function CallInfo() {
  const { phone, allCall, descriptions } = useLocation().state;
  const typeNav = ["已接电话", "呼叫电话", "未接通话"],
    [typeNavStatus, setTypeNavStatus] = useState(0),
    navigate = useNavigate(),
    [myAllCall, setMyAllCall] = useState([]);
  useEffect(() => {
    let myAllCall_ = allCall.filter((item) => {
      let time__ = getTimer_(item.timestamp);
      item.time = time__.split("\t")[0];
      item.time_ = time__.split("\t")[1];
      return item.to.phoneNumber === phone;
    });
    setMyAllCall(myAllCall_);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeNavStatus]);
  return (
    <div className="call_info">
      <NavBar navText="通话历史" backShow={true} bgColor="#ffffff00"></NavBar>
      <div className="c_info_one">
        <div className="info_one_avatar">
          <img src={require("images/avatar.png")} alt="头像"></img>
        </div>
        <div className="info_one_phone">{phone}</div>
        <div className="info_one_address">
          {myAllCall[0] ? myAllCall[0].to.desc : ""}{" "}
          {myAllCall[0] ? myAllCall[0].to.carrier : ""}
        </div>
        <div
          className="info_one_remark flex_justify"
          onClick={() => {
            navigate("/remark", { state: { phone, descriptions } });
          }}
        >
          查看该号码备注通话
          <img
            src={require("images/callHistory/image_04.png")}
            alt="备注"
          ></img>
        </div>
      </div>
      <div className="c_info_type">
        <div className="info_type_nav">
          {typeNav.map((item, index) => {
            return (
              <div className="flex_justify" key={index}>
                <span
                  onClick={() => {
                    setTypeNavStatus(index);
                  }}
                  className={[typeNavStatus === index ? "nav_type_status" : ""]}
                >
                  {item}
                </span>
              </div>
            );
          })}
        </div>
        <div className="info_type_list">
          {myAllCall.map((item, index) => {
            return (
              <div key={index}>
                <div className="flex_justify">
                  <img
                    src={
                      typeNavStatus === 0
                        ? require("images/callHistory/image_05.png")
                        : typeNavStatus === 1
                        ? require("images/callHistory/image_06.png")
                        : require("images/callHistory/image_07.png")
                    }
                    alt=""
                  ></img>
                </div>
                <div>{item.time}</div>
                <div>{item.time_}</div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="call_info_save">
        <div
          onClick={() => {
            navigate("/add_content", { state: { phone } });
          }}
        >
          保存至通讯录
        </div>
      </div> */}
    </div>
  );
}

export default CallInfo;
