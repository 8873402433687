import "./index.scss";
import React from "react";
import { Swiper, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { sjmpUrl, gjdxUrl, znjtUrl, wdqyUrl } from "utils/httpUrl";

function Hypercommunication() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="shome_index">
        <img
          alt="首页背景"
          className="home_index_bg"
          src={require("images/home/image_01.png")}
        ></img>
        <div className="h_index_content">
          {/* 轮播图 */}
          <div className="content_carousel">
            <Swiper
              autoplay
              loop
              indicator={() => null}
              className="carousel_swiper"
            >
              {swiperList.map((item, index) => {
                return (
                  <Swiper.Item key={index}>
                    <div className="c_swiper_item">
                      <img alt="轮播图" src={item}></img>
                    </div>
                  </Swiper.Item>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="hyper">
        <div>
          {huperTypeList.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {item.show ? (
                  <div
                    className="hyper_item"
                    key={index}
                    onClick={() =>
                      item.routerStatus
                        ? item.path
                          ? navigate(item.path)
                          : Toast.show({ content: item.title })
                        : window.location.assign(item.path)
                    }
                  >
                    <div>
                      <img alt="" src={item.icon}></img>
                      <div>{item.title}</div>
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}


const swiperList = [
  require("images/image_01.jpg"),
  require("images/image_02.jpg"),
];

const huperTypeList = [
  {
    title: "AI接线员",
    icon: require("images/hpyer/image_01.png"),
    path: znjtUrl,
    show: false,
  },
  {
    title: "手机名片",
    icon: require("images/hpyer/image_03.png"),
    path: sjmpUrl,
    show: true,
  },
  {
    title: "挂机通知",
    icon: require("images/hpyer/image_04.png"),
    path: gjdxUrl,
    show: true,
  },
  {
    title: "视频推广",
    icon: require("images/hpyer/image_04.png"),
    path: "/add_video_info",
    routerStatus: true,
    show: false,
  },
  {
    title: "营销推广",
    icon: require("images/hpyer/image_05.png"),
    path: "/group_message",
    routerStatus: true,
    show: false,
  },
  {
    title: "我的权益",
    path: wdqyUrl,
    icon: require("images/home/image_13.png"),
    show: true,
  },
];
export default Hypercommunication;
