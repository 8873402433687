import { Navigate } from "react-router-dom";
import Guide from "views/guide";
import Login from "views/login";
import Index from "views/tabBar";
import CommonProblem from "views/features/commonProblem";
import MyRights from 'views/myRights'

export const baseRouter = [
  {
    path: "/guide",
    element: <Guide></Guide>,
  },
  {
    path: "/login",
    element: <Login></Login>,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/home/*",
    element: <Index></Index>,
  },
  {
    path: "/common_problem",
    element: <CommonProblem></CommonProblem>,
    meta: {
      title: "常见问题",
    },
  },
  {
    path: '/my_rights',
    element: <MyRights></MyRights>
  },
  {
    path: "*",
    element: <Navigate to="/guide"></Navigate>,
  },
];
