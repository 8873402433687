import { useEffect, useState, useCallback } from "react";
import NavBar from "components/navBar";
import "./index.scss";
import { Input, Toast } from "antd-mobile";
import CallList from "./callList";
import RemarkPopup from "./remarkPopup";
import { getCallHistory } from "api/callLog";
import { getContacts } from "api/contacts";
import { useNavigate } from "react-router-dom";

function CallHistory() {
  const navTypeList = ["客户资料", "通话历史"];
  const [navTypeStatus, setNavTypeStatus] = useState(0);
  const historyType = ["已接", "呼叫", "未接"];
  const [historyStatus, setHistroyStatus] = useState(0);
  const [seachPhone, setSeachPhone] = useState("");
  const navigate = useNavigate();
  const [allCallHistory, setAllCallHistroy] = useState([]),
    [personList, setPersonList] = useState([]),
    getCallHistoryList = useCallback(() => {
      setAllCallHistroy([]);
      getCallHistory({ cat: historyStatus })
        .then((res) => {
          if (historyStatus === 2 || historyStatus === 1) {
            res.content = res.content.map((item) => {
              item.to_ = item.to;
              item.to = item.from;
              return item;
            });
          }
          setAllCallHistroy(res.content);
          Toast.clear();
        })
        .catch(() => Toast.clear());
    }, [historyStatus]),
    getAddressList = useCallback(() => {
      Toast.show({ content: "加载中...", icon: "loading", duration: 0 });
      if (navTypeStatus === 1) return getCallHistoryList();
      getContacts({ p: 0 }).then((res) => {
        setPersonList(res.content);
        Toast.clear();
      });
    }, [getCallHistoryList, navTypeStatus]);
  useEffect(() => {
    getAddressList();
    return () => {
      Toast.clear();
    };
  }, [getAddressList]);
  return (
    <div className="call_history">
      <div className="c_history_bg"></div>
      <NavBar
        navText={
          <div className="c_history_type flex_justify">
            <div className="h_type_box" style={{ background: "#F3F3F3" }}>
              {navTypeList.map((item, index) => {
                return (
                  <div
                    className={`t_box_item flex_justify ${navTypeStatus === index ? "t_box_item_status" : ""
                      }`}
                    key={index}
                  >
                    <span
                      onClick={() => setNavTypeStatus(index)}
                      style={{
                        color: navTypeStatus === index ? "#333333" : "#C5C5C5",
                      }}
                    >
                      {item}
                    </span>
                  </div>
                );
              })}
              <div
                style={{ left: navTypeStatus * 3.25 + "rem", width: "3.46rem" }}
                className="t_box_move"
              ></div>
            </div>
          </div>
        }
        textColor="#ffffff"
      ></NavBar>
      {navTypeStatus === 0 ? (
        <div>
          <div className="c_history_con">
            <div
              className="h_con_title"
              style={{ color: "#999999", fontSize: "13px" }}
            >
              联系人（{personList.length}）
            </div>
          </div>
          <div className="person_list">
            {personList.map((item) => {
              return (
                <div key={item.id}>
                  <div
                    className="p_list_item"
                    onClick={() => {
                      navigate("/person_info", {
                        state: { id: item.id },
                      });
                    }}
                  >
                    <div className="l_item_left">
                      <img src={require("images/avatar.png")} alt="" />
                    </div>
                    <div className="l_item_right">
                      {item.familyName || item.givenName
                        ? item.familyName + item.givenName
                        : item.phoneNumbers[0].stringValue}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <>
          <div className="history_nav flex_justify">
            <div className="h_type_box_">
              {historyType.map((item, index) => {
                return (
                  <div
                    className={`t_box_item_ flex_justify ${historyStatus === index ? "t_box_item_status_" : ""
                      }`}
                    key={index}
                  >
                    <span onClick={() => setHistroyStatus(index)}>{item}</span>
                  </div>
                );
              })}
              <div
                style={{ left: historyStatus * 2.24 + "rem" }}
                className="t_box_move"
              ></div>
            </div>
          </div>
          <div className="c_history_con">
            <div className="h_con_title">
              <div className="c_title_left flex_justify">最近通话记录</div>
              <div className="c_title_right">
                <div className="flex_justify">
                  <img
                    src={require("images/callHistory/image_01.png")}
                    alt="搜索"
                  ></img>
                  <Input
                    style={{ "--font-size": "0.32.rem" }}
                    placeholder="请输入号码进行查询"
                    clearable
                    onChange={(e) => setSeachPhone(e)}
                    onClear={() => setSeachPhone("")}
                  ></Input>
                </div>
              </div>
            </div>
            <div className="h_con_list">
              <CallList
                allCallHistory={allCallHistory}
                seachPhone={seachPhone}
              ></CallList>
            </div>
          </div>
          <RemarkPopup></RemarkPopup>
        </>
      )}
    </div>
  );
}

export default CallHistory;
