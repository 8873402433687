import "./index.scss";
import { TextArea, Toast } from "antd-mobile";
import { useEffect, useState } from "react";
import PubSub from "pubsub-js";
import { remarkMessage } from "api/callLog";

function RemarkPopup(props) {
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [remarkValue, setRemarkValue] = useState("");
  useEffect(() => {
    PubSub.subscribe("remarkPopup", (msg, data) => {
      setShow(true);
      setId(data.id);
    });
    return () => {
      PubSub.unsubscribe("remarkPopup");
    };
  }, []);
  return show ? (
    <>
      <div
        className="r_popup flex_justify"
        onClick={(e) => {
          e.stopPropagation();
          setShow(false);
          return false;
        }}
      >
        <div className="p_box" onClick={(e) => e.stopPropagation()}>
          <div className="b_title">通话事件备注</div>
          <div className="b_value">
            <TextArea
              className="v_value_input"
              placeholder="请输入备注内容"
              onChange={(e) => setRemarkValue(e)}
            ></TextArea>
          </div>
          <div
            className="b_btn on_Click"
            onClick={() => {
              if (!remarkValue)
                return Toast.show({ content: "请输入备注内容" });
              Toast.show({ content: "添加中...", icon: "loading" });
              remarkMessage({ id, data: { desc: remarkValue } }).then((res) => {
                Toast.show({ content: "添加成功" });
                setTimeout(() => setShow(false), 1500);
              });
            }}
          >
            确定
          </div>
        </div>
      </div>
    </>
  ) : null;
}

export default RemarkPopup;
