import React from "react";
import "./index.scss";
import propTypes from "prop-types";
import { LeftOutline } from "antd-mobile-icons";
import { PubSub } from "pubsub-js";
import Navigate from "route/useNavigate";
class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  // props 默认值
  static defaultProps = {
    bgColor: "#ffffff", // 背景颜色
    backColor: "#000000",
    backShow: false,
    textColor: "#000000",
    navText: "",
    returnClick: null,
  };
  // props 数据类型
  static propTypes = {
    bgColor: propTypes.string,
    backColor: propTypes.string,
  };
  render() {
    return (
      <div className="nav_bar">
        <div
          className="nav_fixed flex_justify"
          style={{ background: this.props.bgColor }}
        >
          <div className="nav_back">
            {this.props.backShow ? (
              <LeftOutline
                color={this.props.backColor}
                onClick={() => {
                  if (this.props.returnClick) {
                    return this.props.returnClick();
                  }
                  PubSub.publish("navigate", { type: 0 });
                }}
              />
            ) : (
              ""
            )}
          </div>
          <span
            className="nav_bar_text"
            style={{ color: this.props.textColor }}
          >
            {this.props.navText}
          </span>
        </div>
        <Navigate></Navigate>
      </div>
    );
  }
}

export default NavBar;
