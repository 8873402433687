import NavBar from "components/navBar";
import React, { useState } from "react";
import PubSub from "pubsub-js";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.scss";
import { useEffect } from "react";
import {
  Popup,
  Collapse,
  List,
  Image,
  TextArea,
  Dialog,
  Toast,
  Input,
  Form,
} from "antd-mobile";
import AddGroupSend from "../addGroupSend";
import { postMsg } from "api/msgs";

function GroupSend() {
  const [sendPersonList, setSendPersonList] = useState({});
  const [popupShow, setPopupShow] = useState(false);
  const [previewShow, setPreviewShow] = useState(false);
  const [customStatus, setCustomStatus] = useState(false);
  const [customInput, setCustomInput] = useState();
  const [inputMsg, setInputMsg] = useState([]);
  const [searchContent, setSearchContent] = useState();
  const [searchId, setSearchId] = useState();
  const [searchInfo, setSearchInfo] = useState();
  const fromRef = React.useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [smsTemValue, setSmsTemValue] = useState("");
  useEffect(() => {
    console.log(location)
    PubSub.subscribe("getSendGroup", (msg, data) => {
      console.log(data)
      if (data) {
        if (data.groupList.length > 0) {
          data.groupList = data.groupList.map((item) => {
            item.personList = data.allPersonList.filter((item_) => {
              return item_.groupIds
                ? item_.groupIds.indexOf(item.id) > -1
                : false;
            });
            return item;
          });
        }
        setSendPersonList(data);
      }
      setPopupShow(false);
    });
    if (location.state) {
      if (location.state && location.state.customStatus) setCustomStatus(true);
      if (location.state.input) {
        let inputMsg_ = [];
        for (let i = 0; i < location.state.input; i++) {
          inputMsg_.push("");
        }
        setInputMsg(inputMsg_);
      }
      let smsValue = location.state.content;
      for (let i = 1; i <= location.state.input; i++) {
        smsValue = smsValue.replace(
          "***",
          `<span style={{ color: "#0EA5FB" }}>【自定义${i}】</span>`
        );
      }
      setSmsTemValue(smsValue);
      if (location.state && location.state.smsInfo) {
        const smsInfo = location.state.smsInfo;
        setSmsTemValue(smsInfo.template.contents);
        console.log(smsInfo);
        setSendPersonList({ personList: smsInfo.to });
      }
    }
    // 判断从vue获取值(VUE获取search值)
    if (location.search) {
      const paramsString = location.search.substring(1)
      const searchParams = new URLSearchParams(paramsString)
      const search_content = searchParams.get('content')
      const search_input = searchParams.get('input')
      const search_id = searchParams.get('id')
      const search_smsInfo = JSON.parse(searchParams.get('smsInfo'))
      if (search_content) setSearchContent(search_content);
      if (search_id) setSearchId(search_id);
      if (search_input) {
        let inputMsg_ = [];
        for (let i = 0; i < search_input; i++) {
          inputMsg_.push("");
        }
        setInputMsg(inputMsg_);
      }
      if (search_content) {
        let smsValue = search_content;
        for (let i = 1; i <= search_input; i++) {
          smsValue = smsValue.replace(
            "***",
            `<span style={{ color: "#0EA5FB" }}>【自定义${i}】</span>`
          );
        }
      }
      setSmsTemValue(smsValue);
      // if (search_smsInfo) {
      //   setSearchInfo(search_smsInfo)
      //   setSearchContent(search_smsInfo.template.contents);
      //   setSearchId(search_smsInfo.id);
      //   setSmsTemValue(search_smsInfo.template.contents);
      //   setSendPersonList({ personList: search_smsInfo.to });
      // }
    }

    return () => {
      PubSub.unsubscribe("getSendGroup");
    };
  }, []);
  const deteleList = (type, id) => {
    if (type === 0) {
      setSendPersonList({
        ...Object.assign(sendPersonList, {
          groupList: sendPersonList.groupList.filter((item) => {
            return item.id !== id;
          }),
        }),
      });
    }
    if (type === 1) {
      setSendPersonList({
        ...Object.assign(sendPersonList, {
          personList: sendPersonList.personList.filter((item) => {
            return item.id !== id;
          }),
        }),
      });
    }
  };
  return (
    <div className="group_send">
      <NavBar navText="群发短信" backShow={true}></NavBar>
      <div className="g_send_line"></div>
      <div className="g_send_message">
        <div className="s_message_title">发送内容</div>
        <div className="s_message_value">
          {customStatus ? (
            <TextArea
              className="s_message_input"
              placeholder="请编辑您要发送的短信内容"
              onChange={(e) => setCustomInput(e)}
            ></TextArea>
          ) : (
            <div className="input_value">
              <div dangerouslySetInnerHTML={{ __html: smsTemValue }}></div>
              <Form
                ref={fromRef}
                style={{ "--font-size": "10px", marginTop: "10px" }}
              >
                {inputMsg.map((item, index) => {
                  return (
                    <Form.Item name={"var_" + (index + 1)} key={index}>
                      <Input
                        placeholder={`请编辑自定义${index + 1}文字内容`}
                      ></Input>
                    </Form.Item>
                  );
                })}
              </Form>
            </div>
          )}
        </div>
      </div>
      {customStatus ? (
        <div className="s_send_hint">
          <span>*</span>提示：自定义编辑的短信模板内容需提交至系统审核后才能发送
        </div>
      ) : (
        <div className="g_send_line"></div>
      )}
      <div className="g_send_person">
        <div className="s_person_title">
          <div>
            发送人员
            <span>
              {sendPersonList.personList && sendPersonList.personList.length > 0
                ? `（${sendPersonList.personList.length}人）`
                : ""}
              {sendPersonList.groupList && sendPersonList.groupList.length > 0
                ? `（${sendPersonList.groupList.length}分组）`
                : ""}
            </span>
          </div>
          {sendPersonList.groupList || sendPersonList.personList ? (
            <div onClick={() => setPreviewShow(true)}>
              <img src={require("images/hpyer/image_10.png")} alt="" />
              效果预览
            </div>
          ) : null}
        </div>
        {(location.state && location.state.smsInfo) || (location.search && searchInfo) ? (
          <List
            style={{
              "--border-top": "none",
              "--border-bottom": "none",
              "--padding-left": "0",
              marginTop: "30px",
            }}
          >
            {sendPersonList.personList &&
              sendPersonList.personList.map((item, index) => {
                return (
                  <List.Item
                    className="l_person_item"
                    key={index}
                    prefix={
                      <div className="flex_justify">
                        <Image
                          src={require("images/avatar.png")}
                          style={{ borderRadius: 20, background: "#F6F6F6" }}
                          fit="cover"
                          width={40}
                          height={40}
                        />
                        <span>{item.phoneNumber}</span>
                      </div>
                    }
                    extra={
                      <div
                        className="flex_justify"
                        style={{
                          paddingRight: "0.4rem",
                        }}
                      >
                        {item.state ? (
                          <>
                            <img
                              style={{
                                width: "0.35rem",
                                height: "0.35rem",
                              }}
                              src={require("images/image_03.png")}
                              alt=""
                            />
                            <span
                              style={{ color: "#0EA5FB", fontSize: "12px" }}
                            >
                              已发送
                            </span>
                          </>
                        ) : (
                          <span style={{ color: "#333333", fontSize: "12px" }}>
                            发送中...
                          </span>
                        )}
                      </div>
                    }
                  ></List.Item>
                );
              })}
          </List>
        ) : (
          <div className="s_person_list">
            <div className="l_group_list">
              {sendPersonList.groupList
                ? sendPersonList.groupList.map((item) => {
                  return (
                    <div key={item.id}>
                      <Collapse>
                        <Collapse.Panel
                          key={item.id}
                          title={
                            <div style={{ display: "flex" }}>
                              <div style={{ flex: 1 }}>{item.name}</div>
                              <div
                                className="flex_justify"
                                style={{
                                  paddingRight: "0.4rem",
                                }}
                              >
                                <img
                                  style={{
                                    width: "0.35rem",
                                    height: "0.35rem",
                                  }}
                                  src={require("images/hpyer/image_12.png")}
                                  alt=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deteleList(0, item.id);
                                  }}
                                />
                              </div>
                            </div>
                          }
                        >
                          <List
                            style={{
                              "--border-top": "none",
                              "--border-bottom": "none",
                            }}
                          >
                            {item.personList.map((item) => {
                              return (
                                <List.Item
                                  className="l_person_item"
                                  key={item.id}
                                  prefix={
                                    <div className="flex_justify">
                                      <Image
                                        src={require("images/avatar.png")}
                                        style={{ borderRadius: 20 }}
                                        fit="cover"
                                        width={40}
                                        height={40}
                                      />
                                      <span>
                                        {item.familyName || item.givenName
                                          ? item.familyName + item.givenName
                                          : item.phoneNumbers[0].stringValue}
                                      </span>
                                    </div>
                                  }
                                ></List.Item>
                              );
                            })}
                          </List>
                        </Collapse.Panel>
                      </Collapse>
                    </div>
                  );
                })
                : null}
              {sendPersonList.personList ? (
                <List
                  style={{
                    "--border-top": "none",
                    "--border-bottom": "none",
                    "--padding-left": "0",
                  }}
                >
                  {sendPersonList.personList.map((item) => {
                    return (
                      <List.Item
                        className="l_person_item"
                        key={item.id}
                        prefix={
                          <div className="flex_justify">
                            <Image
                              src={require("images/avatar.png")}
                              style={{ borderRadius: 20 }}
                              fit="cover"
                              width={40}
                              height={40}
                            />
                            <span>
                              {item.familyName || item.givenName
                                ? item.familyName + item.givenName
                                : item.phoneNumbers[0].stringValue}
                            </span>
                          </div>
                        }
                        extra={
                          <div
                            className="flex_justify"
                            style={{
                              paddingRight: "0.4rem",
                            }}
                          >
                            <img
                              style={{
                                width: "0.35rem",
                                height: "0.35rem",
                              }}
                              src={require("images/hpyer/image_12.png")}
                              alt=""
                              onClick={(e) => {
                                e.stopPropagation();
                                deteleList(1, item.id);
                              }}
                            />
                          </div>
                        }
                      ></List.Item>
                    );
                  })}
                </List>
              ) : null}
            </div>
            <div className="p_list_add" onClick={() => setPopupShow(true)}>
              {sendPersonList.groupList || sendPersonList.personList ? (
                "继续添加"
              ) : (
                <>
                  <img src={require("images/hpyer/image_09.png")} alt="" />
                  添加发送人员
                </>
              )}
            </div>
            {sendPersonList.groupList || sendPersonList.personList ? (
              <div
                className="send_btn on_click"
                onClick={() => {
                  if (sendPersonList.personList.length === 0)
                    return Toast.show({ content: "请选择需要发送的人员" });
                  if (customStatus) {
                    if (!customInput)
                      return Toast.show({ content: "请输入发送内容" });
                    Toast.show({
                      content: "提交审核中...",
                      icon: "loading",
                      duration: 0,
                    });
                    postMsg({
                      data: {
                        to: sendPersonList.personList.map((item) => {
                          return {
                            phoneNumber: item.phoneNumbers[0].stringValue,
                          };
                        }),
                        template: {
                          contents: customInput,
                        },
                      },
                    })
                      .then(async (res) => {
                        Toast.clear();
                        await Dialog.alert({
                          title: "提交成功",
                          content: (
                            <div style={{ textAlign: "center" }}>
                              审核通过后系统将自动将您编辑的短信发送给所选联系人
                            </div>
                          ),
                          closeOnMaskClick: true,
                        });
                        navigate(-1);
                      })
                      .catch((err) => {
                        Toast.show({ content: "提交失败", icon: "fail" });
                      });
                  } else {
                    const fromValue = fromRef.current.getFieldsValue();
                    let contents = location.state ? location.state.content : searchContent;
                    for (let i in fromValue) {
                      if (!fromValue[i])
                        return Toast.show({
                          content: "请将自定义内容填写完整",
                        });
                      contents = contents.replace("***", fromValue[i]);
                    }
                    let template;
                    if (inputMsg.length > 0 || (location.state ? location.state.id : searchId)) {
                      template = {
                        id: location.state ? location.state.id : searchId,
                        contents,
                        params: fromValue,
                      };
                    } else {
                      template = {
                        contents: location.state ? location.state.content : searchContent,
                      };
                    }
                    Toast.show({
                      content: "提交中...",
                      icon: "loading",
                      duration: 0,
                    });
                    postMsg({
                      data: {
                        to: sendPersonList.personList.map((item) => {
                          return {
                            phoneNumber: item.phoneNumbers[0].stringValue,
                          };
                        }),
                        template,
                      },
                    }).then(async (res) => {
                      Toast.clear();
                      await Dialog.alert({
                        title: "任务提交成功",
                        closeOnMaskClick: true,
                      });
                      navigate(-1);
                    });
                  }
                }}
              >
                {customStatus ? "提交审核" : "确认提交"}
              </div>
            ) : null}
          </div>
        )}
      </div>
      <Popup visible={popupShow}>
        <div className="send_popup">
          <AddGroupSend sendPersonList={sendPersonList}></AddGroupSend>
        </div>
      </Popup>
      <Popup visible={previewShow}>
        <div className="preview">
          <div className="preview_title">
            短信效果预览
            <img
              onClick={() => setPreviewShow(false)}
              src={require("images/hpyer/image_13.png")}
              alt=""
            />
          </div>
          <div className="preview_content">
            <img src={require("images/hpyer/image_11.png")} alt="" />
            <div>
              <div className="p_content_right">
                <div dangerouslySetInnerHTML={{ __html: smsTemValue }}></div>
                <div>
                  <img src={require("images/avatar.png")} alt="" />
                </div>
              </div>
              <div className="p_content_left flex_items">
                <div>
                  <img src={require("images/avatar.png")} alt="" />
                </div>
                <div>收到！</div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
}

const messageTemp = (
  <div>
    仅{<span>***</span>}
    元!即享原价{<span>860</span>}
    元广州最大最专业的**车行爱车养护限量版洗车卡1张!
    !智能电脑洗车+除尘10次+室内蒸汽消毒一次+元广州最大最专业的**车行爱车养护限量版洗车卡1张!
    !智能电脑洗车+除尘10次+室内蒸汽消毒一次+室内负离子消毒三次+会员金卡一张，精心呵护!诚信服务!品质至.上!超值体验。地址
    {<span>艾溪湖南路很</span>}
  </div>
);

export default GroupSend;
