import Home from "views/tabBar/home";
// import AddressBook from "views/tabBar/addressBook";
import CallHistory from "views/tabBar/callHistory";
import Hypercommunication from "views/tabBar/Hypercommunication";
import Profile from "views/tabBar/profile";
import GroupMessage from "views/cyper/groupMessage";

export const homeRouter = [
  // {
  //   path: "",
  //   element: <Home />,
  //   meta: {
  //     title: "首页",
  //     icon: require("images/tabbar/image_01.png"),
  //     selestIcon: require("images/tabbar/image_01_.png"),
  //   },
  // },
  {
    // path: "call_history",
    path: "",
    element: <CallHistory />,
    meta: {
      title: "客户资料",
      icon: require("images/tabbar/image_06.png"),
      selestIcon: require("images/tabbar/image_06_.png"),
    },
  },
  // {
  //   path: "address_book",
  //   element: <GroupMessage backShow="backShow" />,
  //   meta: {
  //     title: "营销推广",
  //     icon: require("images/tabbar/image_07.png"),
  //     selestIcon: require("images/tabbar/image_07_.png"),
  //   },
  // },
  {
    path: "hypercommunication",
    element: <Hypercommunication />,
    meta: {
      title: "超级通讯",
      icon: require("images/tabbar/image_04.png"),
      selestIcon: require("images/tabbar/image_04_.png"),
    },
  },
  {
    path: "profle",
    element: <Profile />,
    meta: {
      title: "我的",
      icon: require("images/tabbar/image_05.png"),
      selestIcon: require("images/tabbar/image_05_.png"),
    },
  },
];
