import { Routes, Route } from "react-router-dom";
import React from "react";
class HomeRouter extends React.Component {
  render() {
    return (
      <Routes>
        {this.props.navBarList.map((item, index) => {
          return (
            <Route
              exact
              key={index}
              path={item.path}
              element={item.element}
            ></Route>
          );
        })}
        <Route exact path="*" element={<div>404</div>}></Route>
      </Routes>
    );
  }
}

export default HomeRouter;
