import callCss from "./index.module.scss";
import { Empty, Loading } from "antd-mobile";
import { DownOutline } from "antd-mobile-icons";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getTimer_ } from "utils/index";
import PubSub from "pubsub-js";
function CallList(props) {
  const [page, setPage] = useState(1);
  const [allCall, setAllCall] = useState([]);
  const [searchStauts, setSearchStatus] = useState(true);
  const [moreStatus, setMoreStatus] = useState(true);
  const navigate = useNavigate();
  const searchPhone = useCallback(() => {
    if (props.seachPhone) {
      setAllCall(
        props.allCallHistory.filter((item) => {
          return item.to.phoneNumber.indexOf(props.seachPhone) > -1;
        })
      );
    } else {
      setAllCall(props.allCallHistory);
    }
  }, [props.seachPhone, props.allCallHistory]);
  useEffect(() => {
    searchPhone();
    return () => {};
  }, [searchPhone]);
  const getPageCall = () => {
    if (!moreStatus) return;
    if (page < 1) {
      setSearchStatus(false);
      setTimeout(() => {
        setAllCall([...allCall, ...props.allCallHistory]);
        setSearchStatus(true);
        setPage(page + 1);
        searchPhone();
      }, 1500);
    } else {
      setSearchStatus(false);
      setTimeout(() => {
        setSearchStatus(true);
        setMoreStatus(false);
      }, 1500);
    }
  };
  const seeHistoryInfo = (phone, item) => {
    navigate(`/call_info`, {
      state: { phone, allCall, descriptions: item.descriptions },
    });
  };
  return (
    <div className={callCss.call_list}>
      {allCall.length !== 0 ? (
        <div>
          {allCall.map((item, index) => {
            return (
              <div className={callCss.call_list_item} key={index}>
                <div className={callCss.c_item_left}>
                  <img
                    onClick={() => {
                      seeHistoryInfo(item.to.phoneNumber, item);
                    }}
                    src={require("images/avatar.png")}
                    alt="头像"
                  ></img>
                </div>
                <div className={callCss.c_item_right}>
                  <div
                    onClick={() => {
                      seeHistoryInfo(item.to.phoneNumber, item);
                    }}
                    className={callCss.c_right_one}
                  >
                    {item.to.phoneNumber}
                    {index === 0 ? (
                      <div>
                        <span>最近通话</span>
                      </div>
                    ) : null}
                  </div>
                  <div
                    onClick={() => {
                      seeHistoryInfo(item.to.phoneNumber, item);
                    }}
                    className={callCss.c_right_two}
                  >
                    <div>{item.to.desc + " " + item.to.carrier}</div>
                    <div className="flex_items">
                      {getTimer_(item.timestamp)}
                    </div>
                  </div>
                  <div className={callCss.c_right_three}>
                    <div>
                      <div
                        onClick={() =>
                          PubSub.publish("remarkPopup", { id: item.id })
                        }
                      >
                        备注此通话
                        <img
                          src={require("images/callHistory/image_02.png")}
                          alt=""
                        ></img>
                      </div>
                    </div>
                    <div>
                      <div
                        onClick={() => {
                          seeHistoryInfo(item.to.phoneNumber, item);
                        }}
                      >
                        历史通话
                        <img
                          style={{ width: ".45rem", height: ".45rem" }}
                          src={require("images/callHistory/image_03.png")}
                          alt=""
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {/* <div className={callCss.call_list_more}>
            {searchStauts ? (
              <div onClick={() => getPageCall()}>
                <span>{moreStatus ? "查看更多" : "没有更多了"}</span>
                {moreStatus ? <DownOutline /> : null}
              </div>
            ) : (
              <Loading color="primary" />
            )}
          </div> */}
        </div>
      ) : (
        <Empty
          description="暂无数据"
          imageStyle={{ width: 100 }}
          style={{ padding: "100px 0" }}
        ></Empty>
      )}
    </div>
  );
}

export default CallList;
