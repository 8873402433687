import { useLocation, useNavigate } from "react-router-dom";
import { List, SwipeAction } from "antd-mobile";
import NavBar from "components/navBar";
import "./index.scss";
import { useEffect } from "react";
import { getGroupContact } from "api/contactGroup";
import { useState } from "react";
import { getContacts } from "api/contacts";

function GroupList() {
  const { id } = useLocation().state,
    navigate = useNavigate(),
    [groupInfo, setGroupInfo] = useState({}),
    [groupPerson, setGroupPerson] = useState([]);
  useEffect(() => {
    getGroupContact({ id }).then((res) => {
      setGroupInfo(res);
      getContacts().then((res) => {
        setGroupPerson(
          // eslint-disable-next-line array-callback-return
          res.content.filter((item) => {
            if (item.groupIds) {
              if (item.groupIds.indexOf(id) > -1) {
                return item;
              }
            }
          })
        );
      });
    });
    return () => {};
  }, [id]);
  return (
    <div className="group_list">
      <NavBar
        navText={`${groupInfo.name || ""}（${groupPerson.length}）`}
        backShow={true}
      ></NavBar>
      <div className="g_list_line"></div>
      <div className="g_list_box">
        <List style={{ "--border-top": "none", "--border-bottom": "none" }}>
          {groupPerson.map((item) => {
            return (
              <div key={item.id} className="l_box_item">
                <SwipeAction
                  rightActions={[
                    { key: "del", text: "移出分组", color: "#FF6A6A" },
                  ]}
                >
                  <List.Item
                    arrow={false}
                    onClick={() =>
                      navigate("/person_info", { state: { id: item.id } })
                    }
                    title={
                      <div className="b_item_info">
                        <img alt="" src={require("images/avatar.png")}></img>
                        <div>{item.familyName + item.givenName}</div>
                      </div>
                    }
                  ></List.Item>
                </SwipeAction>
              </div>
            );
          })}
        </List>
      </div>
      <div className="group_add">
        <div onClick={() => navigate("/add_group_person", { state: { id } })}>
          <img alt="" src={require("images/addressBook/image_02.png")}></img>
          添加成员
        </div>
      </div>
    </div>
  );
}


export default GroupList;
