import { getStorage, setStorage } from "./storage";
const appId = "client_id=wxa6268ff5778e48e2";
const {origin, pathname} = window.location

// 登陆
export const loginUrl = `https://app.dasongnet.com/graham/index.html?${appId}&redirect_uri=${encodeURIComponent(`${origin}${pathname}#/home`)}`;

// AI接线员
export const znjtUrl = `https://zhineng.lio8.com/1/api/sxtlogin?token=${getStorage()}&${appId}`;

// 手机名片
export const sjmpUrl = `https://app.dasongnet.com/sjmp/?jwt=${getStorage()}&${appId}`;

// 挂机短信
export const gjdxUrl = `https://app.dasongnet.com/gjdx/?jwt=${getStorage()}&${appId}`;

// 我的权益
export const wdqyUrl = `https://equity.liocms.com/EbaQZj?jwt=${getStorage()}&${appId}`;

// 视频推广
export const spxlUrl = ``;
