import instance from "utils/axios";

export const getMsg = (params) => {
  return instance({
    url: "/msgs",
    params,
  });
};

export const postMsg = (options) => {
  return instance({
    url: "/msgs",
    method: "POST",
    data: options.data,
  });
};
