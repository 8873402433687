import GroupMessage from "views/cyper/groupMessage/index";
import GroupSend from "views/cyper/groupMessage/groupSend";
import { AddInfo } from "views/home/addInfo";
import { ComboSee } from "views/home/comboSee/inde";
import { TemplateSet } from "views/home/templateSet";

export const cyperRouter = [
  {
    path: "/group_message",
    element: <GroupMessage></GroupMessage>,
  },
  {
    path: "/group_send",
    element: <GroupSend></GroupSend>,
  },
  {
    path: "/add_video_info",
    element: <AddInfo></AddInfo>,
  },
  {
    path: "/combo_see",
    element: <ComboSee></ComboSee>,
  },
  {
    path: "/template_set",
    element: <TemplateSet></TemplateSet>,
  },
];
