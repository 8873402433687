import NavBar from "components/navBar";
import { getStorage } from "utils/storage";
import axios from "axios";
import { TextArea, Toast, Collapse } from "antd-mobile";
import problemCss from "./index.module.scss";
import './ant.scss'
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function CommonProblem() {
  const [value, setValue] = useState(""),
  navigate = useNavigate();
  return (
    <div className="problem">
      <NavBar backShow={true} navText="反馈与建议"></NavBar>
      <div className={problemCss.problem}>
        <div className={problemCss.problem_title}>
          <span></span>
          您的问题或建议
        </div>
        <div className={problemCss.problem_input}>
          <TextArea
            value={value}
            placeholder="请输入您的问题或建议"
            className={problemCss.problem_input_}
            onChange={(e) => {
              setValue(e);
            }}
          ></TextArea>
        </div>
        <div className={problemCss.problem_btn}>
          <div
            onClick={() => {
              
               axios({
                url: `https://zhineng.lio8.com/1/api/toemail?token=${getStorage()}`,
                method: "POST",
                params: {
                  title: "常见问题",
                  content: value,
                },
              })
                .then((res) => {
                  if (res.data.code == 0) {
                    Toast.show({
                      content: "提交成功",
                      icon: "success",
                      maskClickable: false,
                      afterClose: () => navigate(-1, { replace: true }),
                    });
                  } else {
                    Toast.show({
                      content: "提交失败",
                      icon: "fail",
                      maskClickable: false,
                    });
                  }
                })
                .catch((err) => {
                  Toast.show({ content: "数据请求失败，请稍后重试" });
                });

            }}
          >
            提交
          </div>
        </div>
        <div className={problemCss.problem_title}>
          <span></span>
          常见问题
        </div>
        <div className={problemCss.problem_list}>
          <Collapse>
            {problemList().map((item, index) => {
              return (
                <Collapse.Panel key={index} title={item.title}>
                  <div className={problemCss.problem_list_value}>
                    {item.value}
                  </div>
                </Collapse.Panel>
              );
            })}
          </Collapse>
        </div>
      </div>
    </div>
  );
}

const problemList = () => {
  let problemList = [];
  // for (let i = 1; i <= 5; i++) {
  //   problemList.push({
  //     title: `常见问题${i}？`,
  //     value:
  //       "问题解答常见问题一常见问题一常见问题一常见问题一常见 问题一常见问题一",
  //   });
  // }
  return problemList;
};

export default CommonProblem;
