import NavBar from "components/navBar";
import { useEffect, useState } from "react";
import { Toast, Checkbox } from "antd-mobile";
import { useLocation } from "react-router-dom";
import { getContacts } from "api/contacts";
import "./index.scss";

function AddGroupPerson() {
  const [allPersonList, setAllPersonList] = useState([]);
  const [selectPerson, setSelectPerson] = useState([]);
  const { id } = useLocation().state;
  useEffect(() => {
    getContacts().then((res) => {
      setAllPersonList([...res.content]);
    });
    return () => {};
  }, []);
  const checkPerson = (person) => {
    let selectPerson_ = selectPerson;
    const allPersonList_ = allPersonList.map((item) => {
      if (person.id === item.id) {
        item.checked = !item.checked;
        if (person.checked) {
          selectPerson_.push(item);
        } else {
          selectPerson_ = selectPerson_.filter((item_) => {
            return item.id !== item_.id;
          });
        }
      }
      return item;
    });
    setAllPersonList([...allPersonList_]);
    setSelectPerson([...selectPerson_]);
  };
  return (
    <div className="add_group_person">
      <NavBar
        navText={
          selectPerson.length > 0
            ? `已选择（${selectPerson.length}）`
            : "选择联系人"
        }
        backShow={true}
      ></NavBar>
      <div className="group_select">
        <div className="g_select_box">
          {selectPerson.map((item) => {
            return (
              <div className="s_box_item" key={item.id}>
                <div>
                  <img alt="" src={require("images/avatar.png")}></img>
                </div>
                <div className="ellipsis">
                  {(item.familyName + item.givenName).toString()}
                </div>
                <div>
                  <img
                    alt=""
                    onClick={() => checkPerson(item)}
                    src={require("images/addressBook/image_03.png")}
                  ></img>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="group_hit"></div>
      <div className="g_person_list">
        <Checkbox.Group>
          {allPersonList.map((item) => {
            return (
              <div className="p_list_item" key={item.id}>
                <div className="p_list_left">
                  <img alt="" src={require("images/avatar.png")}></img>
                </div>
                <div className="p_list_right">
                  <div>{(item.familyName + item.givenName).toString()}</div>
                  <div>
                    <Checkbox
                      onChange={() => checkPerson(item)}
                      checked={item.checked}
                    ></Checkbox>
                  </div>
                </div>
              </div>
            );
          })}
        </Checkbox.Group>
      </div>
      <div className="add_group_btn">
        <div
          onClick={() => {
            if (selectPerson.length === 0)
              return Toast.show({ content: "请选择需要添加联系人" });
          }}
        >
          确认添加
        </div>
      </div>
    </div>
  );
}

export default AddGroupPerson;
