const storageName = "Authorization";

export const getStorage = () => {
  return sessionStorage.getItem(storageName);
};

export const setStorage = (data) => {
  return sessionStorage.setItem(storageName, data);
};

export const removeStorage = () => {
  return sessionStorage.removeItem(storageName);
};
