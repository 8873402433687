import { useState, useEffect } from "react";
import NavBar from "components/navBar";
import { Checkbox, Toast } from "antd-mobile";
import PubSub from "pubsub-js";
import "./index.scss";
import { getContactGroup } from "api/contactGroup";
import { getContacts } from "api/contacts";

function AddGroupSend() {
  const [groupList, setGroupList] = useState([]);
  const [personList, setPsersonList] = useState([]);
  const [allGroupList, setAllGroupList] = useState([]);
  const [allPersonList, setAllPersonList] = useState([]);
  useEffect(() => {
    (async () => {
      Toast.show({
        content: "加载中...",
        icon: "loading",
        duration: 0,
        maskClickable: false,
      });
      // await getContactGroup().then((res) => setAllGroupList(res.content));
      await getContacts({ p: 0 }).then((res) => setAllPersonList(res.content));
      Toast.clear();
    })();
    return () => {
      Toast.clear();
    };
  }, []);
  const getGroupList = (list) => {
    let _groupList_ = allGroupList.filter((item) => {
      return list.indexOf(item.id) !== -1;
    });
    setGroupList(_groupList_);
  };
  const getPersonList = (list) => {
    let _personList_ = allPersonList.filter((item) => {
      return list.indexOf(item.id) !== -1;
    });
    setPsersonList(_personList_);
  };
  return (
    <div className="add_group_send">
      <NavBar
        returnClick={() => PubSub.publish("getSendGroup")}
        navText="选择成员"
        backShow={true}
      ></NavBar>
      {/* <div className="line"></div>
      <div className="g_send_group">
        <div className="s_group_title">群组列表</div>
        <div className="s_group_list">
          <div>
            <Checkbox.Group onChange={(e) => getGroupList(e)}>
              {allGroupList.map((item) => {
                return (
                  <div className="g_list_item" key={item.id}>
                    <div>
                      <img src={require("images/avatar.png")} alt="" />
                    </div>
                    <div className="ellipsis">{item.name}</div>
                    <div className="flex_justify">
                      <Checkbox
                        value={item.id}
                        style={{ "--adm-color-primary": "#0EA5FB" }}
                      />
                    </div>
                  </div>
                );
              })}
            </Checkbox.Group>
          </div>
        </div>
      </div> */}
      <div className="line"></div>
      <div className="g_send_person">
        <div className="s_person_title">通讯录列表</div>
        <div className="s_person_list">
          <Checkbox.Group onChange={(e) => getPersonList(e)}>
            {allPersonList.map((item) => {
              return (
                <div className="p_list_item" key={item.id}>
                  <div>
                    <img src={require("images/avatar.png")} alt="" />
                  </div>
                  <div>
                    {item.familyName || item.givenName
                      ? item.familyName + item.givenName
                      : item.phoneNumbers[0].stringValue}
                  </div>
                  <div>
                    <Checkbox
                      value={item.id}
                      style={{ "--adm-color-primary": "#0EA5FB" }}
                    ></Checkbox>
                  </div>
                </div>
              );
            })}
          </Checkbox.Group>
        </div>
      </div>
      <div className="g_send_add">
        <div
          className="on_click"
          onClick={() => {
            PubSub.publish("getSendGroup", {
              groupList,
              personList,
              allGroupList,
              allPersonList,
            });
            Toast.show({
              content: "添加成功",
              icon: "success",
              duration: 500,
            });
          }}
        >
          确认添加
        </div>
      </div>
    </div>
  );
}

export default AddGroupSend;
